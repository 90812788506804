import React, { FC, useEffect, useState } from "react";
import cancel from "../../assets/images/cancel.svg";
import edit from "../../assets/images/edit_round.svg";
import user from "../../assets/images/single_bill.svg";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../store";
import DocumentPreViewer from "../../components/commons/document-preview";
import {
  getCurrencyNumberWithSymbol,
  getMaskedMobileNumber,
} from "../../helpers/get-currency-number";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAxiosClient from "../../hooks/useAxiosClient";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import { Button, Input, Popover, Select2 } from "../../components/ui";
import {
  getPaymentHistory,
  updateEditAdvPayment,
  updateRefresh,
} from "../../reducers/tradeSlice";
import Calender from "../../components/commons/calender";
import { getPaymentStatus, getPaymentStatusColor } from "../../helpers/getPaymentStatus";
import { Padding } from "@mui/icons-material";

const PaymentHistory: FC<{
  traderId: number;
  tradeId: number;
  onClose: (paymentDone: boolean) => void;
  recordType?: string;
  onEdit: (isEdit: boolean) => void;
  role: string;
}> = ({ traderId, tradeId, onClose, recordType, onEdit, role }) => {
  const {
    paymentHistoryObj,
    paymentUpdateStatus,
    tradeDetail,
    adminId,
    refresh,
  } = useSelector((state: TRootState) => ({
    paymentHistoryObj: state.trades.paymentHistoryObj,
    paymentUpdateStatus: state.trades.paymentUpdateStatus,
    tradeDetail: state.trades.tradeDetails,
    adminId: state.auth.adminId,
    refresh: state.trades.refresh,
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handlePostCall } = useAxiosClient();
  const paymentTypeVal = paymentUpdateStatus
    ? paymentHistoryObj?.inOutWard
    : recordType;
  const [cancelPopup, setCancelPopup] = useState(false);
  const [openCalender, setOpenCalendar] = useState(false);

  const [cancellationReason, setCancellationReason] = useState<string>("");

  const cancelRecordPayment = () => {
    if (!cancellationReason) {
      toast.error('Please select reason for cancellation');
      return;
    }
    handlePostCall({
      URL: "admin/trade-platform/trades/payments",
      apiParams: {
        action: "CANCEL",
        adminId: adminId,
        amount: paymentHistoryObj?.amount,
        date: moment(paymentHistoryObj?.date).format("YYYY-MM-DD"),
        images: [],
        inOutWard: paymentHistoryObj?.inOutWard,
        pmtMode: paymentHistoryObj?.pmtMode,
        pmtType: paymentHistoryObj?.pmtType,
        tradeId: tradeId,
        traderId: traderId,
        id: paymentHistoryObj?.id,
        status: 0,
        comments: paymentHistoryObj?.comments || "",
        reqAdv: {
          advApproved: adminId,
          advDisb: paymentHistoryObj?.amount,
          disbDate: moment(paymentHistoryObj?.date).format("YYYY-MM-DD"),
          tradeId: tradeId,
        },
        pmtReceiptDate: paymentHistoryObj?.pmtReceiptDate
          ? moment(paymentHistoryObj?.pmtReceiptDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        utr: paymentHistoryObj?.utr,
        desc: paymentHistoryObj?.desc,
        rejectedReason: cancellationReason,
        addtComments: additionalComments,
        pmtStatus: "CANCELLED",
      },
    })
      .then(({ data }) => {
        toast.success("Cancelled successfully");
        dispatch(updateRefresh({ refresh: !refresh }));
        navigate(
          `/trade-detail/${tradeDetail?.srcPartyId}/${tradeDetail?.tradeId}?tab=OPEN`
        );
        setCancelPopup(false);
        onClose(false);
        // getTradeDetail();
      });
  };
  const [pmtReceiptDate, setPmtReceiptDate] = useState(
    paymentHistoryObj?.date
      ? moment(paymentHistoryObj?.date).format("DD-MMM-YYYY")
      : moment().format("DD-MMM-YYYY")
  );
  const [utr, setUtr] = useState(
    paymentHistoryObj?.utr ? paymentHistoryObj?.utr : ""
  );
  const [paymentDesc, sertpaymentDesc] = useState(
    paymentHistoryObj?.desc ? paymentHistoryObj?.desc : ""
  );
  const [additionalComments, setAdditionalComments] = useState("");

  const savePaymentReceipt = () => {
    handlePostCall({
      URL: "admin/trade-platform/trades/payments",
      apiParams: {
        action: "UPDATE",
        adminId: adminId,
        amount: paymentHistoryObj?.amount,
        date: moment(paymentHistoryObj?.date).format("YYYY-MM-DD"),
        images: [],
        inOutWard: paymentHistoryObj?.inOutWard,
        pmtMode: paymentHistoryObj?.pmtMode,
        pmtType: paymentHistoryObj?.pmtType,
        tradeId: tradeId,
        traderId: traderId,
        id: paymentHistoryObj?.id,
        status: 2,
        comments: paymentHistoryObj?.comments || "",
        reqAdv: {
          advApproved: adminId,
          advDisb: paymentHistoryObj?.amount,
          disbDate: moment(paymentHistoryObj?.date).format("YYYY-MM-DD"),
          tradeId: tradeId,
        },
        pmtReceiptDate: moment(pmtReceiptDate).format("YYYY-MM-DD"),
        utr: utr,
        desc: paymentDesc,
        pmtStatus: "ACCEPTED",
      },
    })
      .then(({ data }) => {
        toast.success("Updated successfully");
        dispatch(updateRefresh({ refresh: !refresh }));
        navigate(
          `/trade-detail/${tradeDetail?.srcPartyId}/${tradeDetail?.tradeId}?tab=OPEN`
        );
        setCancelPopup(false);
        onClose(false);
        // getTradeDetail();
      });
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  return (
    <div>
      <div className="flex pb-2">
        <div
          className="grow-[4] overflow-y-auto"
          style={{
            height: isMobile ? "calc(-18rem + 100vh)" : "calc(-8.4rem + 100vh)",
          }}
        >
          <div className="border-primary p-4 br-10 m-4">
            <div className="block md:flex border-b pb-3 justify-between">
              <div className="flex border-b md:border-none pb-2 md:pb-0">
                <img src={user} className="w-12 h-12 br-100" />
                <div className="ml-2 flex flex-col">
                  <span className="font-semibold text-sm">
                    From : {paymentHistoryObj?.inOutWard === "INWARD"
                      ? tradeDetail?.destTradeName
                      : tradeDetail?.srcTradeName}
                  </span>
                  <span className="text-sm font-light">
                    Trader-
                    {paymentHistoryObj?.inOutWard === "INWARD"
                      ? tradeDetail?.destPartyId
                      : tradeDetail?.srcPartyId}{" "}
                    |{" "}
                    {getMaskedMobileNumber(
                      paymentHistoryObj?.inOutWard === "INWARD"
                        ? tradeDetail?.destMobile || ''
                        : tradeDetail?.srcMobile || ""
                    )}
                  </span>
                </div>
              </div>

              <div className=" flex flex-col pt-2 md:pt-0">
                <span className="text-sm font-light">Date</span>
                <p className="font-semibold text-sm">
                  {moment(paymentHistoryObj?.date).format("DD-MMM-YYYY")}
                </p>
              </div>
            </div>
            {paymentHistoryObj?.payoutId != null ? (
              <div className="flex text-sm border-b py-3 justify-between">
                <div className="flex flex-col">
                  <span className="font-light">Payout ID</span>
                  <p className="font-semibold">{paymentHistoryObj?.payoutId}</p>
                </div>
                <div className="flex flex-col">
                  <span className="font-light">Fund Account ID</span>
                  <p className="font-semibold">
                    {paymentHistoryObj?.fundAccId}
                  </p>
                </div>
                <div className="flex flex-col"></div>
              </div>
            ) : paymentHistoryObj?.inOutWard == 'OUTWARD'
              ?
              (
                <div className="flex text-sm border-b py-3 justify-between">
                  <div className="flex  flex-col">
                    <span className=" font-light">Payment Status</span>
                    <span className="font-semibold">
                      <span className={`${getPaymentStatusColor(paymentHistoryObj?.pmtStatus)}`}>
                        {getPaymentStatus(paymentHistoryObj?.pmtStatus)}
                      </span>
                    </span>
                  </div>
                </div>
              ) : (
                <div className="flex text-sm border-b py-3 justify-between">
                  <div className="flex flex-col w-[100%]">
                    <span className=" font-light">Payment Status</span>
                    <span className="font-semibold">
                      {paymentHistoryObj?.pmtStatus ? (
                        paymentHistoryObj?.pmtStatus === "ACCEPTED" ? (
                          <span className="color-primary">Approved</span>
                        ) : paymentHistoryObj?.pmtStatus === "PENDING" ? (
                          <span className="color-orange">Approval pending</span>
                        ) : paymentHistoryObj?.pmtStatus === "CANCELLED" ? (
                          <span className="color-red">Cancelled</span>
                        ) : (
                          paymentHistoryObj?.pmtStatus === "ACCEPTED"
                        )
                      ) : (
                        "-"
                      )}
                    </span>
                  </div>
                  {
                    paymentTypeVal == "INWARD" && paymentHistoryObj?.pmtStatus == 'CANCELLED' &&
                    <div className="flex flex-col text-sm w-[100%]">
                      <span className="font-light">Reason for Rejection</span>
                      <span className="font-semibold">
                        {paymentHistoryObj?.rejectedReason}
                      </span>
                    </div>
                  }
                  {
                    paymentTypeVal == "INWARD" && paymentHistoryObj?.pmtStatus == 'CANCELLED' &&
                    <div className="flex flex-col text-sm w-[100%]">
                      {
                        paymentHistoryObj?.addtComments &&
                        <span className="font-light">Additional Comments</span>
                      }
                      <span className="font-semibold">
                        {paymentHistoryObj?.addtComments}
                      </span>
                    </div>
                  }
                </div>
              )}
            <div className="flex text-sm border-b py-3 justify-between">
              <div className="flex  flex-col w-[100%]">
                <span className=" font-light">
                  {paymentTypeVal == "INWARD"
                    ? "Amount Received"
                    : "Amount Paid"}
                </span>
                <span className="font-semibold">
                  {getCurrencyNumberWithSymbol(paymentHistoryObj?.amount, true)}
                </span>
              </div>

              {
                paymentHistoryObj?.payoutId ?
                  <div className="flex flex-col w-[100%]">
                    <span className="font-light">
                      Fees
                    </span>
                    <span className="font-semibold">
                      {getCurrencyNumberWithSymbol(paymentHistoryObj?.fees, true)}
                    </span>
                  </div>
                  :
                  <div className="flex flex-col w-[100%]">
                    <span className="font-light">
                      {paymentTypeVal == "INWARD"
                        ? "Received Till Date"
                        : "Paid Till Date"}
                    </span>
                    <span className="font-semibold">
                      {paymentTypeVal == "OUTWARD" &&
                        getCurrencyNumberWithSymbol(tradeDetail?.outwardPmt, true)}
                      {paymentTypeVal == "INWARD" &&
                        getCurrencyNumberWithSymbol(tradeDetail?.inwardPmt, true)}
                    </span>
                  </div>
              }

              {
                paymentHistoryObj?.payoutId ?
                  <div className="flex flex-col w-[100%]">
                    <span className="font-light">
                      Tax
                    </span>
                    <span className="font-semibold">
                      {getCurrencyNumberWithSymbol(paymentHistoryObj?.tax, true)}
                    </span>
                  </div>
                  : <div className="flex flex-col w-[100%]">
                    <span className="font-light">
                      {paymentTypeVal == "INWARD"
                        ? "Balance Receivable"
                        : "Balance Payable"}
                    </span>
                    <p className="font-semibold">
                      {" "}
                      {paymentTypeVal === "INWARD"
                        ? getCurrencyNumberWithSymbol(
                          (Number(tradeDetail?.totalPayable) +
                            Number(tradeDetail?.destPf) -
                            Number(tradeDetail?.destDis) || 0) -
                          (tradeDetail?.inwardPmt || 0),
                          true
                        )
                        : getCurrencyNumberWithSymbol(
                          (Number(tradeDetail?.totalReceivable) -
                            Number(tradeDetail?.srcPf) +
                            Number(tradeDetail?.srcDis) || 0) -
                          (tradeDetail?.outwardPmt || 0),
                          true
                        )}
                    </p>
                  </div>
              }
            </div>
            <div className="flex text-sm border-b justify-between py-3">
              <div className="flex flex-col w-[100%]">
                <span className="font-light">Payment Type</span>
                <p className="font-semibold">{paymentHistoryObj?.pmtType}</p>
              </div>
              <div className="flex flex-col w-[100%]">
                <span className="font-light">Mode </span>
                <p className="font-semibold">{paymentHistoryObj?.pmtMode}</p>
              </div>
              <div className="flex flex-col w-[100%]">
                {
                  paymentTypeVal == 'OUTWARD' &&
                  <div className="flex flex-col w-[100%]">
                    <span className=" font-light">UTR Number</span>
                    <span className="font-semibold">
                      {paymentHistoryObj?.utr || "-"}
                    </span>
                  </div>
                }
              </div>
            </div>
            <div className="flex text-sm flex-col py-3 border-b">
              <span className="font-light">Comments</span>
              <p className="font-semibold">
                {paymentHistoryObj?.comments || "-"}
              </p>
            </div>
            {paymentHistoryObj?.payoutId && (
              <div>
                <div className="py-3 border-b">
                  <span className="font-light">Note 1</span>
                  <p className="font-semibold">
                    {paymentHistoryObj?.note1 || "-"}
                  </p>
                </div>
                <div className="py-3 border-b">
                  <span className="font-light">Note 2</span>
                  <p className="font-semibold">
                    {paymentHistoryObj?.note2 || "-"}
                  </p>
                </div>
              </div>
            )}
            {paymentHistoryObj?.images &&
              paymentHistoryObj?.images?.length != 0 &&
              <div className="mt-3">
                <span className="text-sm font-light mb-2">
                  Uploaded Documents
                </span>
                <div className="flex flex-row overflow-auto w-full hide-scroll-bar">
                  {paymentHistoryObj?.images &&
                    paymentHistoryObj?.images?.length != 0 &&
                    paymentHistoryObj?.images.map((file, i) => (
                      <div
                        key={file.url}
                        className={"shrink-0 px-2"}
                        style={{ width: "15rem", height: "10rem" }}
                      >
                        <div className="border-2 p-2 rounded flex justify-center relative min-h-full">
                          <a href={file.url} target="_blank">
                            <DocumentPreViewer
                              docType={file.docType}
                              url={file.url}
                            />
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            }
          </div>
          {paymentHistoryObj?.pmtStatus != null &&
            (paymentHistoryObj?.pmtStatus == "ACCEPTED" ||
              paymentHistoryObj?.pmtStatus == "CANCELLED") &&
            paymentTypeVal === "INWARD" && (
              <div className="border-primary br-10 m-4">
                <div
                  className={`py-2 px-4 color-primary font-semibold bg-light-primary`}
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  Confirm Receipt
                </div>
                <div className="px-4 py-2">
                  {" "}
                  <div className="flex text-sm border-b py-3 justify-between">
                    <div className="flex  flex-col">
                      <span className=" font-light">Payment Receipt Date</span>
                      <span className="font-semibold">
                        {paymentHistoryObj?.pmtReceiptDate
                          ? moment(paymentHistoryObj?.pmtReceiptDate).format(
                            "DD-MMM-YYYY"
                          )
                          : "-"}
                      </span>
                    </div>
                    <div className="flex  flex-col">
                      <span className=" font-light">UTR Number</span>
                      <span className="font-semibold">
                        {paymentHistoryObj?.utr || "-"}
                      </span>
                    </div>
                  </div>
                  <div className="flex text-sm py-3 justify-between">
                    <div className="flex  flex-col">
                      <span className=" font-light">
                        Receipt ID / Description
                      </span>
                      <span className="font-semibold">
                        {paymentHistoryObj?.desc || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {paymentHistoryObj?.pmtStatus != null &&
            paymentHistoryObj?.pmtStatus == "PENDING" &&
            paymentTypeVal === "INWARD" && (
              <div className="border-primary br-10 m-4 mb-4">
                <div
                  className={`py-2 px-4 color-primary font-semibold bg-light-primary`}
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  Confirm Receipt
                </div>
                <div className="px-4 py-2">
                  {" "}
                  <div className="flex text-sm py-3 gap-4">
                    <div className="relative grow-[3]" style={{ width: "50%"}}>
                      <Popover
                        open={openCalender}
                        element={
                          <div>
                            <Input
                              label="Payment Receipt Date *"
                              onFocus={() => {
                                setOpenCalendar(true);
                              }}
                              value={moment(pmtReceiptDate).format(
                                "DD-MMM-YYYY"
                              )}
                              inputStyle={{ marginTop: "10px" }}
                            />
                          </div>
                        }
                        onClose={() => {
                          setOpenCalendar(false);
                        }}
                      >
                        <Calender
                          mode="single"
                          initialDateRange={{}}
                          onChange={({ date }: { date: string }) => {
                            setOpenCalendar(false);
                            setPmtReceiptDate(
                              moment(date, "YYYY-MMM-DD").format("YYYY-MM-DD")
                            );
                          }}
                          currentDate={pmtReceiptDate}
                          maxDate={moment().format("YYYY-MM-DD")}
                          style={{ width: "18rem"}}
                        />
                      </Popover>
                    </div>

                    <div className="grow-[3]" style={{ width: "50%" }}>
                      {/* <span className=" font-light">UTR Number</span> */}
                      <Input
                        type="text"
                        label={"UTR Number"}
                        value={utr}
                        onChange={(e) => setUtr(e.target.value)}
                        inputStyle={{ marginTop: "10px" }}
                      />
                    </div>
                  </div>
                  <div className="flex text-sm py-3 justify-between">
                    <div className="">
                      <span className="text-sm font-semibold ">
                        Description{" "}
                      </span>
                      <textarea
                        value={paymentDesc}
                        onChange={(e) => sertpaymentDesc(e.target.value)}
                        className="h-[100px] w-[100%] p-2 mt-2 border br-10"
                      />
                    </div>
                  </div>
                  {role == "ADMIN" && (
                    <div className="flex justify-end pb-2">
                      {" "}
                      <Button
                        variant="contained"
                        className=""
                        onClick={() => {
                          savePaymentReceipt();
                          // cancelRecordPayment();
                        }}
                      >
                        SAVE
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
        {role == "ADMIN" &&
          tradeDetail?.tradeStatus != "CLOSED" &&
          paymentHistoryObj?.payoutId == null && (
            <div
              className="w-50 md:w-50 flex flex-row md:flex-col border-l p-4 gap-3 md:gap-0 absolute md:relative bottom-0 border-t md:border-t-none justify-between md:justify-start"
              style={{ width: isMobile ? "100%" : "" }}
            >
              <button
                onClick={() => {
                  onEdit(true);
                  dispatch(updateEditAdvPayment({ editAdvancePayment: true }));
                }}
                className="flex flex-col justify-center items-center"
              >
                <img src={edit} className="cursor-pointer w-8" />
                <span className="text-xs">EDIT</span>
              </button>

              {paymentHistoryObj?.pmtStatus != "CANCELLED" && (
                <button
                  className="mt-5 flex flex-col justify-center items-center"
                  onClick={() => {
                    setCancelPopup(true);
                  }}
                >
                  <img src={cancel} className="cursor-pointer w-8" />
                  <span className="text-xs">CANCEL</span>
                </button>
              )}
            </div>
          )}
      </div>
      <>
        <Modal
          open={cancelPopup}
          onClose={() => {
            setCancelPopup(false);
          }}
          width={isMobile ? "100%" : "30vw"}
        >
          <>
            <ModalHeader
              title={"Receipt Status: Cancel Payment"}
              content={<></>}
            />
            <div className="mt-1 px-6">
              <span className="text-sm pb-1">
                Reason for Cancellation
                <span className="color-red">*</span>
              </span>
              <Select2
                value={cancellationReason}
                lable=""
                options={[
                  {
                    label: 'Duplicate Entry',
                    value: 'Duplicate Entry',
                  },
                  {
                    label: 'Invalid Receipt',
                    value: 'Invalid Receipt',
                  },
                  {
                    label: 'Payment not Received',
                    value: 'Payment not Received',
                  }
                ]}
                onChange={(selectedReason) => {
                  setCancellationReason(selectedReason.value);
                }}
              />
            </div>
            <div className="pt-2">
              <span className="text-sm px-6">
                Additional Comments
              </span>
              <div className="px-6 pt-1">
                <textarea
                  value={additionalComments}
                  onChange={(e) => {
                    setAdditionalComments(e.target.value);
                  }}
                  className="h-[200px] w-[100%] p-2 border border-gray br-10"
                />
              </div>
            </div>
            <div className="flex items-center bottom-0 right-0 px-4 py-4 border-top gap-2">
              <Button
                variant="primaryLight"
                className="w-full"
                text="CANCEL"
                onClick={() => {
                  setCancelPopup(false);
                }}
              />
              <Button
                variant="contained"
                className="w-full"
                text="SAVE"
                onClick={() => {
                  cancelRecordPayment();
                }}
              />
            </div>
          </>
        </Modal>
      </>
    </div>
  );
};
export default PaymentHistory;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TRootState } from "../../store";
import { TAllTradeDetails } from "../../types";

const useAllTradeDetailsReport = () => {
  const { handleGetCall } = useAxiosClient();

const [isLoading, setIsLoading] = useState(true);


  const { adminId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
  }));

  const [allTradeDetailReports, setAllTradeDetailReports] = useState<
    TAllTradeDetails[]
  >([]);
  useEffect(() => {
    handleGetCall<TAllTradeDetails[]>(
      `reports/mandi-recon/open-trades/omr/adminId/${adminId}`
    ).then(({ data }) => {
      if (data) {
        setAllTradeDetailReports(data);
     setIsLoading(false);
      } else {
        setAllTradeDetailReports([]);
     setIsLoading(false);
      }
    });
  }, []);

  return {
    setAllTradeDetailReports,
    allTradeDetailReports,
     isLoading
  };
};
export default useAllTradeDetailsReport;


import { FaChevronRight, FaSearch } from "react-icons/fa";
import comingsoon from "../../assets/images/coming_soon.gif";
import { Button, Input, Popover } from "../../components/ui";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAccountStatusDisplayIcon } from "../../helpers/getText";
import UserAccountStatusString from "./CustomerDetails/status-string";
import useAllBorrowers from "../../hooks/useBorrowers";
import PartnerInfo from "../../components/partner-info";
import market from "../../assets/images/market.png";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
import { MdArrowDropDown } from "react-icons/md";
import timer from "../../assets/images/timer-img.png";
import hold from "../../assets/images/hold.svg";
import reject from "../../assets/images/reject.svg";
import tickmark from "../../assets/images/verified.svg";
import blocked from "../../assets/images/blocked.svg";
import { Checkbox } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import BeneficiaryDetails from "./BorrowerDetails/benificiary-details";
import EmptyProfile from "../../assets/images/profile.jpg";
import { UpdateplatformType } from "../../reducers/borrowerSlice";
import { useDispatch } from "react-redux";

const statusList = [
  {
    label: "Under Review",
    value: "UNDER REVIEW",
    icon: timer,
    color: "color-orange",
  },
  {
    label: "KYC Approved",
    value: "ACTIVE",
    icon: tickmark,
    color: "color-primary",
  },

  {
    label: "Rejected",
    value: "REJECTED",
    icon: reject,
    color: "color-red",
  },
  { label: "Blocked", value: "BLOCKED", icon: blocked, color: "color-red" },
  { label: "On Hold", value: "HOLD", icon: hold, color: "color-orange" },
];
const Borrowers: React.FC<{}> = ({}) => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { allBorrowers, role } = useAllBorrowers();
  const dispatch = useDispatch();
  const [tradeStatusValue, setTradeStatusValue] = useState<string[]>([]); //filter
  const [selectStatus, setselectStatus] = useState<string[]>([]); // selecting
  const [statusOpen, setStatusOpen] = useState(false); //popup open

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  // const filteredBorrwers = allBorrowers.filter((borrower) => {
  //   let value = true;
  //   if (tradeStatusValue.length > 0) {
  //     value = tradeStatusValue.includes(borrower.bwrStatus);
  //   } else if (searchText.length > 0) {
  //     value =
  //       borrower.fullName.toLowerCase().includes(searchText) ||
  //       borrower.marketName?.toLowerCase().includes(searchText) ||
  //       borrower.cpId?.toString().includes(searchText)
  //     // borrower.beneficiaries.some(
  //     //   (beneficiary) =>
  //     //     beneficiary.partyName?.toLowerCase().includes(searchText) ||
  //     //     beneficiary.appId?.toString().includes(searchText)
  //     // );
  //   }
  //   return value;
  // });

  const filteredBorrwers = allBorrowers.filter((borrower) => {
    let isStatus = true;
    let isSearch = true;

    // Check if trade status filter applies
    if (tradeStatusValue.length > 0) {
      isStatus =
        tradeStatusValue.includes(borrower.bwrStatus) ||
        borrower.beneficiaries.some((i) =>
          tradeStatusValue.includes(i.benfiStatus)
        );
    }

    // Check if search text filter applies
    if (searchText.length > 0) {
      isSearch =
        borrower.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
        borrower.marketName?.toLowerCase().includes(searchText.toLowerCase()) ||
        borrower.cpId?.toString().includes(searchText) ||
        borrower.beneficiaries?.some(
          (beneficiary) =>
            beneficiary.partyName
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            beneficiary.appId?.toString().includes(searchText) ||
            beneficiary.partyId?.toString().includes(searchText)
        );
    }

    // Both conditions should be true to include this borrower
    return isStatus && isSearch;
  });
  return (
    <div className="container mx-4 py-4">
      <div className="flex justify-between">
        <div className="flex-grow">
          <div className="relative w-[50%] border border-gray-300 rounded focus-within:border-green-500 focus-within:shadow-lg transition-all duration-300">
            <FaSearch
              className="absolute color-primary"
              size={15}
              style={{ top: "14px", left: "6px" }}
            />
            <Input
              type="text"
              palceholder="Search by Borrowers Name / Beneficiary Name / Market Name / CASH ID"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              inputStyle={{ textIndent: "22px", padding: "8px 5px" }}
            />
          </div>
        </div>
        {/* <Button>Add Trader</Button> */}

        <div className="">
          <button
            onClick={() => {
              setStatusOpen(true);
            }}
            className="flex items-center bg-white border border-gray color-primary rounded-lg px-2 py-2 focus:outline-none focus:ring-2 focus:ring-green-200"
          >
            <FilterAltIcon className="w-4 h-4 mr-2" />
            Filter By:{"  "}
            <span className="text-gray-700 px-1">
              Status({tradeStatusValue.length})
            </span>
            <span className="text-gray-700 px-4">
              <MdArrowDropDown />
            </span>
          </button>

          <div className="">
            <Popover
              open={statusOpen}
              onClose={() => {
                setStatusOpen(false);
              }}
              className="w-[230px] h-auto"
              style={{ marginLeft: "7.2rem" }}
            >
              <div>
                {statusList.map((status) => (
                  <div
                    style={{ backgroundColor: "white", width: "250px" }}
                    className="flex items-center px-3 py-1 gap-2 cursor-pointer hv-primary"
                    onClick={() => {
                      const newValue = selectStatus.includes(status.value)
                        ? selectStatus.filter((item) => item !== status.value)
                        : [...selectStatus, status.value];
                      setselectStatus(newValue);
                    }}
                  >
                    <div>
                      <span>
                        <Checkbox
                          {...label}
                          checked={selectStatus.includes(status.value)}
                        />
                      </span>
                      <span className="font-semibold text-sm">
                        {status.label}
                      </span>
                    </div>
                  </div>
                ))}

                <div className="flex p-3 gap-2" style={{ width: "220px" }}>
                  <button
                    className=" align-items-center rounded-md h-[40px]"
                    style={{ backgroundColor: "#d7f3dd", width: "100px" }}
                    onClick={() => {
                      setTradeStatusValue([]);
                      setselectStatus([]);
                      setStatusOpen(false);
                    }}
                  >
                    <span className="color-primary"> Clear All</span>
                  </button>
                  <button
                    className="bg-primary align-items-center rounded-md h-[40px]"
                    style={{ backgroundColor: "white", width: "100px" }}
                    onClick={() => {
                      setTradeStatusValue(selectStatus);
                      setStatusOpen(false);
                    }}
                  >
                    <span className="text-white">Apply</span>
                  </button>
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>

      <div>
        <div className="h-full pt-4">
          <div>
            <div
              className="flex bg-light-primary border text-sm font-medium py-2"
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                borderBottom: "0px",
              }}
            >
              <div className="text-center px-2" style={{ flexBasis: "4rem" }}>
                #
              </div>
              <div className="grow-[2.5] basis-0 px-2">
                Borrowers Name | CASH ID
              </div>
              <div className="grow-[2.5] basis-0 px-2 flex">
                Beneficiary Name | Market Name{" "}
              </div>
              <div className="grow-[2] basis-0 px-2">Cash Limit (₹)</div>
              <div className="grow-[2] basis-0 px-2">Total Loans</div>
              <div className="grow-[2] basis-0 px-2">Overdue Amount</div>
              <div className="grow-[2.5] basis-0 px-2">
                <div className="flex cursor-pointer relative">
                  <div>Status</div>
                  <div>{/* <MdArrowDropDown className="w-6 h-6" /> */}</div>
                </div>
              </div>
            </div>

            <div
              className="overflow-auto"
              style={{ height: "calc(100vh - 17rem)" }}
            >
              {allBorrowers.length > 0 ? (
                filteredBorrwers.map((item, index) => (
                  <div
                    className="border mb-4"
                    style={{
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                    key={index}
                  >
                    <div
                      className="flex border-b text-sm py-2 cursor-pointer items-center"
                      onClick={() => {
                        navigate(
                          `/borrower-detail/${item.cpId}/${item.platform}`
                        );
                      }}
                      style={{ backgroundColor: "#D7F3DD66" }}
                    >
                      <span
                        className="text-center px-2"
                        style={{ flexBasis: "4rem" }}
                      >
                        {index + 1}
                      </span>
                      <span className="grow-[2.5] basis-0 flex px-2 text-sm">
                        <PartnerInfo
                          profilePic=""
                          name={item.fullName}
                          shortName={""}
                          partnerType={"CASH ID "}
                          partnerId={item.cpId}
                          partnerMobile={item.mobile}
                          partyName={item.addressLine}
                        />
                      </span>
                      <div className="grow-[2.5] basis-0 flex flex-col px-2">
                        <div className="flex items-center gap-3">
                          <img
                            className="w-10 h-10 rounded-full object-cover"
                            src={market}
                          ></img>
                          <span className="text-sm font-semibold">
                            {item.marketName}
                          </span>
                        </div>
                      </div>
                      <div className="grow-[2] basis-0 px-2">
                        <div className="flex flex-col">
                          <span className="text-sm font-semibold">
                            {getCurrencyNumberWithSymbol(item.eligibleLimit) ||
                              0}
                          </span>
                          <span className="text-sm color-sub-text">
                            Avl Limit:{" "}
                            {getCurrencyNumberWithSymbol(item.availableLimit) ||
                              0}
                          </span>
                        </div>
                      </div>
                      <div className="grow-[2] basis-0 px-2">
                        <span className="font-semibold text-sm">
                          {item.totalLoans}
                        </span>
                      </div>
                      <div className="grow-[2] basis-0 px-2">
                        <span className="font-semibold text-sm color-red">
                          {item.overDueAmt}
                        </span>
                      </div>
                      <div className="grow-[2.5] basis-0 px-2 flex items-center gap-3">
                        <UserAccountStatusString
                          statusIcon={getAccountStatusDisplayIcon({
                            status: item.bwrStatus,
                          })}
                        />

                        <FaChevronRight />
                      </div>
                    </div>
                    <div
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      {item?.beneficiaries?.length > 0 &&
                        item?.beneficiaries
                          .filter((beneficiary) => {
                            if (tradeStatusValue.length > 0) {
                              return tradeStatusValue.includes(
                                beneficiary.benfiStatus
                              );
                            } else if (searchText.length > 0) {
                              return beneficiary.partyName
                                .toLowerCase()
                                .includes(searchText.toLowerCase());
                            }
                            return true;
                          })
                          .map((beneficiary: any, i: any) => (
                            <div
                              className={`flex text-sm py-2 cursor-pointer items-center bg-white ${
                                i != item.beneficiaries.length - 1
                                  ? "border-b"
                                  : ""
                              }`}
                              onClick={() => {
                                navigate(
                                  `/beneficiary-detail/${item.cpId}/${beneficiary.appId}/${item?.platform}`
                                );
                              }}
                            >
                              <span
                                className="text-center px-2"
                                style={{ flexBasis: "4rem" }}
                              >
                                {i + 1}
                              </span>
                              <span className="grow-[2.5] basis-0 flex  px-2 text-sm">
                                <span className="font-semibold text-sm">
                                  Ben ID - {beneficiary.benId}
                                </span>
                              </span>
                              <div className="grow-[2.5] basis-0 flex flex-col px-2">
                                <PartnerInfo
                                  profilePic={
                                    beneficiary?.profilePic || EmptyProfile
                                  }
                                  name={beneficiary.partyName}
                                  shortName={""}
                                  partnerType={"Farmer ID"}
                                  partnerId={beneficiary.partyId}
                                  partnerMobile={beneficiary.mobile}
                                  partyName={beneficiary.address}
                                />
                              </div>
                              <div className="grow-[2] basis-0 px-2">-</div>
                              <div className="grow-[2] basis-0 px-2">
                                <span className="text-sm font-semibold">
                                  {beneficiary.totalLoans}
                                </span>
                              </div>
                              <div className="grow-[2] basis-0 px-2 color-red font-semibold">
                                {beneficiary.overDueAmt}
                              </div>
                              <div className="grow-[2.5] basis-0 px-2 flex items-center gap-3">
                                <UserAccountStatusString
                                  statusIcon={getAccountStatusDisplayIcon({
                                    status: beneficiary.benfiStatus,
                                  })}
                                />

                                <FaChevronRight />
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex items-center justify-center py-8">
                  <img src={NodataAvailable} alt="image" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Borrowers;

import { FC, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaXmark } from "react-icons/fa6";
import { FaBell } from "react-icons/fa6";
import { deviceInfoContext } from "../../../context";
import logo from "../../../assets/images/mandi-logo.svg";
import DefultLogo from "../../../assets/images/single_bill.svg";
import { useSelector } from "react-redux";
import { TRootState } from "../../../store";
import getTradeStatus from "../../../helpers/getTradeStatus";
import { PanelSlider } from "../../../components/ui";
import cancelIcon from "../../../assets/images/close.svg";
import Notifications from "./Notifications";
import "./notifi.scss";
import usePayNotifications from "../../../hooks/usePaymentNotifications";
const PageHeader: FC<{
  title?: string;
  showBackButton?: boolean;
  showTitle?: boolean;
  showUserInfo?: boolean;
  topVal?: string;
  fromScreen?: string;
  subText?: string;
  imgsrc?: string;
}> = ({
  title,
  showBackButton,
  showTitle,
  showUserInfo,
  topVal,
  fromScreen,
  subText,
}) => {
  const navigate = useNavigate();
  const { width } = useContext(deviceInfoContext);
  const [notification, setNotifications] = useState(false);

  const { name, traderId, tradeType, loggedin } = useSelector(
    (state: TRootState) => ({
      name: "",
      traderId: "",
      tradeType: "",
      loggedin: state.auth.loggedin,
    })
  );
  const { adminId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
  }));
  const back = () => {
    if (showTitle === true && fromScreen == "View Trade") {
      navigate("/");
    } else {
      navigate(-1);
    }
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [key, setKey] = useState(0);

  const handleClick = () => {
    setKey((prevKey) => prevKey + 1);
  };

  const { notifications } = usePayNotifications({});

  const panelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node)
      ) {
        setNotifications(false);
      }
    };
    if (notification) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notification]);

  const delayNotifications = () => {
    setTimeout(() => {
      setNotifications(true);
    }, 500);
  };

  return (
    <>
      <div
        className="bg-white md:shadow-md flex items-center pr-2 font-semibold w-full sticky z-[999] md:static md:z-auto"
        style={{ height: "3.8rem", top: topVal }}
      >
        {showBackButton === true && (
          <div
            className="pr-3 pl-2 cursor-pointer h-full flex"
            onClick={() => {
              back();
            }}
          >
            <FaArrowLeft className="mt-auto mb-auto" />
          </div>
        )}
        {showTitle === true && (
          <div className="px-2 flex flex-col w-full">
            <span>{title}</span>
          </div>
        )}
        {showUserInfo === true && (
          <div className="flex justify-end items-center w-full">
            <div className="relative px-2 cursor-pointer"
             onClick={() => {
              handleClick();
              delayNotifications();
            }}
            >
              <div
                key={key}
                className="animate-shake"
              >
                <FaBell className="color-primary w-7 h-7" />
              </div>
              {notifications.length > 0 && (
                <span
                  className="absolute top-0 right-0 w-3 h-3 bg-orange-600 rounded-full flex justify-center items-center"
                  style={{ top: "-6px", padding: "10px", right: "4px" }}
                >
                  <span className="text-xs text-white font-size">
                    {notifications.length}
                  </span>
                </span>
              )}
            </div>

            <div className="h-6 ml-2 border-l-2 border-gray-400"></div>

            <div
              className="flex gap-2 cursor-pointer items-center ml-4"
              onClick={() => {
                navigate("/my-profile");
              }}
            >
              <img src={DefultLogo} className="w-8 h-8" />
              <div className="flex flex-col text-xs">
                <span>{name}</span>
                <span className="hidden md:block">{`User Id: ${adminId}`}</span>
              </div>
            </div>
          </div>
        )}
        {loggedin === false && (
          <span className="flex ml-auto gap-2">
            <img src={logo} className="ml-auto w-16 px-1" alt="" />
          </span>
        )}
      </div>

      <div>
        <PanelSlider
          direction={isMobile ? "bottom_to_top" : "right_to_left"}
          open={notification}
          onClose={() => {
            setNotifications(false);
          }}
          style={{ width: isMobile ? "100%" : "40vw" }}
        >
          <div
            ref={panelRef}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="py-4 px-4 border-b flex items-center justify-between">
              <div>
                <h2 className="text-base font-semibold">Notifications</h2>
              </div>
              <div>
                <img
                  src={cancelIcon}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    setNotifications(false);
                  }}
                />
              </div>
            </div>
            <div
              className="px-4 py-4 overflow-auto"
              style={{ height: "calc(100vh - 6rem)" }}
            >
              <Notifications status={true} modalClose={setNotifications} />
            </div>
          </div>
        </PanelSlider>
      </div>
    </>
  );
};
export default PageHeader;

import { Button, Input, Select2 } from "../../../../components/ui";
import ModalHeader from "../../../../components/ui/modal/modal-header";
import tickmark from "../../../../assets/images/tickmark.png";
import reject from "../../../../assets/images/reject.svg";
import usePayoutDetails from "./usePayoutDetails";
import { TPayoutFields } from "../../../../types";
import Modal from "../../../../components/ui/modal";
import OtpVerification from "../../otpVerification";
import { useState } from "react";

const AddPayoutAccount: React.FC<{
  onClose: () => void;
  fromBorrower?: boolean;
}> = ({ onClose, fromBorrower }) => {
  const {
    addPayoutDetails,
    setPayoutDetails,
    onSubmit,
    loading,
    otpVerificationModal,
    setOtpVerificationModal,
    sendOtp,
    onVerifyOtp,
    otpVal,
    setOtpVal,
  } = usePayoutDetails();

  return (
    <div>
      <ModalHeader title={"Payout Account"} content={<></>} />
      <div className="px-4">
        <div className="flex mt-3">
          <div className="w-[25%] mr-2">
            <Input
              label="Account Holder"
              required={true}
              className="text-sm font-semibold"
              type="text"
              value={addPayoutDetails.accountHolder}
              onChange={(e) => {
                setPayoutDetails(e.target.value, TPayoutFields.accountHolder);
              }}
            />
          </div>
          <div className="w-[25%] mr-2">
            <Input
              label="Account Number"
              required={true}
              className="text-sm font-semibold"
              type="number"
              value={addPayoutDetails.accountNumber}
              onChange={(e) => {
                setPayoutDetails(e.target.value, TPayoutFields.accountNumber);
              }}
            />
          </div>
          <div className="w-[25%] mr-2">
            <Input
              label="Bank Name"
              className="text-sm font-semibold"
              type="text"
              value={addPayoutDetails.bankName}
              onChange={(e) => {
                setPayoutDetails(e.target.value, TPayoutFields.bankName);
              }}
            />
          </div>
          <div className="w-[25%] mr-2">
            <Input
              label="IFSC Code"
              required={true}
              className="text-sm font-semibold"
              type="text"
              value={addPayoutDetails.ifscCode}
              onChange={(e) => {
                setPayoutDetails(e.target.value, TPayoutFields.ifscCode);
              }}
            />
          </div>
          {/* <div className="w-[15%]">
                            <Select2
                                value={addPayoutDetails.status}
                                lable="Status*"
                                options={[
                                    {
                                        label: "Active",
                                        value: "ACTIVE",
                                        image: tickmark,
                                        textColor: addPayoutDetails.status == 'ACTIVE' ? '' : 'color-primary'
                                    },
                                    {
                                        label: "In-Active",
                                        value: "INACTIVE",
                                        image: reject,
                                        textColor: 'color-red'
                                    },
                                ]}
                                onChange={(selectedOption) => {
                                    setPayoutDetails(selectedOption.value, TPayoutFields.status);
                                }}
                            />
                        </div> */}
        </div>
        <div className="flex mt-3">
          <div className="w-[25%] mr-2">
            <Select2
              classNames="h-[40px]"
              value={addPayoutDetails.accountType}
              lable="Account Type*"
              options={[
                {
                  label: "Bank Account",
                  value: "bank_account",
                },
                // {
                //     label: "Virtual Payment Address",
                //     value: "virtual_payment_address",
                // },
              ]}
              onChange={(selectedOption) => {
                setPayoutDetails(
                  selectedOption.value,
                  TPayoutFields.accountType
                );
              }}
            />
          </div>
          {!fromBorrower && (
            <div className="w-[25%] mr-2">
              <Input
                label="Trader Type"
                required={true}
                disabled={true}
                className="text-sm font-semibold"
                type="text"
                value={addPayoutDetails.traderType}
              />
            </div>
          )}
          <div className="w-[25%] mr-2">
            <Input
              label="Contact Number"
              required={true}
              className="text-sm font-semibold"
              type="number"
              value={addPayoutDetails.contactNumber}
              onChange={(e) => {
                setPayoutDetails(e.target.value, TPayoutFields.contactNumber);
              }}
            />
          </div>
          <div className="w-[25%]">
            <Input
              label="Email Address"
              className="text-sm font-semibold"
              type="text"
              value={addPayoutDetails.emailAddress}
              onChange={(e) => {
                setPayoutDetails(e.target.value, TPayoutFields.emailAddress);
              }}
            />
          </div>
          {fromBorrower && <div className="w-[25%] mr-2"></div>}
        </div>
        <div className="flex mt-3">
          <div className="w-[40%] mr-2">
            <Input
              label="Note 1"
              className="text-sm font-semibold"
              type="text"
              value={addPayoutDetails.note1}
              onChange={(e) => {
                setPayoutDetails(e.target.value, TPayoutFields.note1);
              }}
            />
          </div>
          <div className="w-[60%]">
            <Input
              label="Note 2"
              className="text-sm font-semibold"
              type="text"
              value={addPayoutDetails.note2}
              onChange={(e) => {
                setPayoutDetails(e.target.value, TPayoutFields.note2);
              }}
            />
          </div>
        </div>
      </div>
      <div className="border-b px-2 py-2"></div>
      <div className="modal-footer p-4 flex justify-between items-center">
        <div>
          <Button
            text="CANCEL"
            className="w-[100px] ml-[10px]"
            variant="primaryLight"
            onClick={() => {
              onClose();
            }}
          />
        </div>
        <div>
          <Button
            text="SUBMIT"
            className="w-[100px] mr-[10px]"
            disabled={loading}
            onClick={() => {
              sendOtp("ADD_ACCOUNT");
              // onSubmit(onClose);
            }}
          />
        </div>
      </div>
      <>
        {otpVerificationModal && (
          <Modal
            open={otpVerificationModal}
            onClose={() => {
              setOtpVerificationModal(false);
            }}
            width={"30vw"}
          >
            <>
              <ModalHeader title={"OTP Verification"} content={<></>} />
              <div className="py-3 px-3 md:px-6">
                <OtpVerification
                  onSubmit={onSubmit}
                  onClose={onClose}
                  onVerifyOtp={onVerifyOtp}
                  sendOtp={sendOtp}
                  otpVal={otpVal}
                  setOtpVal={setOtpVal}
                  addPayoutDetails={addPayoutDetails}
                  fromAddAccount={true}
                />
              </div>
            </>
          </Modal>
        )}
      </>
    </div>
  );
};

export default AddPayoutAccount;

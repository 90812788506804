import React from "react";
import NodataAvailable from "../../../../src/assets/images/NodataAvailable.svg";
import useTradeLedger from "../../../hooks/useTradeLedger";
import { useSelector } from "react-redux";
import { TRootState } from "../../../store";
import useCustomerDocs from "../../../hooks/useCustomerDocs";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
} from "../../../helpers/get-currency-number";
import moment from "moment";

const Ledgers: React.FC = () => {
  const { ledgerDetails } = useTradeLedger();

  const { traderType } = useSelector((state: TRootState) => ({
    traderType: state.trades.traderType,
  }));

  return (
    <div className="">
      <div className="grid grid-cols-4 gap-4 border bg-white p-4 rounded-lg mt-[20px] mb-[20px]">
        <div className="text-center">
          <span className="color-sub-text">Business Amount</span>
          {traderType === "SOURCE" ? (
            <p className="font-semibold">
              {getCurrencyNumberWithSymbol(
                ledgerDetails?.tradesAmt.businessAmt
              ) || 0}{" "}
            </p>
          ) : (
            <p className="font-semibold">
              {getCurrencyNumberWithSymbol(
                ledgerDetails?.tradesAmt.businessAmt
              ) || 0}{" "}
            </p>
          )}
        </div>

        <div className="text-center">
          <span className="color-sub-text">Platform Fee</span>
          {traderType === "SOURCE" ? (
            <p className="font-semibold">
              {getCurrencyNumberWithSymbol(
                ledgerDetails?.tradesAmt.platformFee
              ) || 0}
            </p>
          ) : (
            <p className="font-semibold">
              {getCurrencyNumberWithSymbol(
                ledgerDetails?.tradesAmt.platformFee
              ) || 0}
            </p>
          )}
        </div>

        <div className="text-center">
          <span className="color-sub-text">Transportation Fee</span>
          {traderType === "SOURCE" ? (
            <p className="font-semibold">
              {getCurrencyNumberWithSymbol(
                ledgerDetails?.tradesAmt.transportationFee
              ) || 0}
            </p>
          ) : (
            <p className="font-semibold">
              {getCurrencyNumberWithSymbol(
                ledgerDetails?.tradesAmt.transportationFee
              ) || 0}
            </p>
          )}
        </div>

        <div className="text-center">
          <span className="color-sub-text">Close Trades Count</span>
          {traderType === "SOURCE" ? (
            <p className="font-semibold">
              {ledgerDetails?.tradesAmt.closedCount || 0}
            </p>
          ) : (
            <p className="font-semibold">
              {ledgerDetails?.tradesAmt.closedCount || 0}
            </p>
          )}
        </div>
      </div>

      <div
        className="overflow-auto bg-white rounded-t-md overflow-y-scroll scrollbar-hide"
        style={{
          height: "calc(100vh - 15rem)",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <table className="min-w-full border-collapse border">
          <thead className="bg-light-primary rounded-t-md sticky top-0">
            <tr>
              <th className="px-3 py-2 text-left text-sm font-semibold">#</th>
              <th className="px-3 py-2 text-left text-sm font-semibold">
                Tx ID | Date
              </th>
              <th className="px-3 py-2 text-left text-sm font-semibold">
                Item | Mode
              </th>
              <th className="px-3 py-2 text-left text-sm font-semibold">
                {traderType === "SOURCE" ? "Paid" : "Received"} (₹)
              </th>
              <th className="px-3 py-2 text-left text-sm font-semibold">
                {traderType === "SOURCE" ? "To be Paid" : "To be Received"} (₹)
              </th>
              <th className="px-3 py-2 text-left text-sm font-semibold">
                Balance (₹)
              </th>
            </tr>
          </thead>

          <tbody>
            {ledgerDetails != null && ledgerDetails?.trades.length > 0 ? (
              ledgerDetails?.trades.map((details, index) => (
                <React.Fragment key={details.tradeId}>
                  <tr className="bg-green-50">
                    <td colSpan={2} className="px-3 py-2">
                      <span className="color-blue font-semibold">
                        {details.tradeId}
                      </span>
                      <br />
                      <span className="font-semibold text-sm">
                        {details.date?.split(" | ")[0]}
                      </span>
                      <br />
                      <span className="color-sub-text text-sm">
                        {details.date?.split(" | ")[1] &&
                        moment(
                          details.date?.split(" | ")[1],
                          "YYYY-MM-DD",
                          true
                        ).isValid()
                          ? moment(details.date?.split(" | ")[1]).format(
                              "DD-MMM-YYYY"
                            )
                          : "-"}
                      </span>{" "}
                    </td>
                    <td colSpan={3} className="px-3 py-2 text-sm text-gray-800">
                      <span className="font-semibold">TO: {details.item}</span>
                    </td>
                    <td
                      className={`px-3 py-2 text-'left' font-semibold ${
                        details.balance < 0 ? "color-red" : "color-primary"
                      }`}
                    >
                      {getCurrencyNumberWithOutSymbol(details?.balance) || 0}
                    </td>
                  </tr>

                  {details.tradeReports.map((report, reportIndex) => (
                    <tr key={report.id} className="border-t text-sm">
                      <td className="px-3 py-2">{reportIndex + 1}</td>
                      <td className="px-3 py-2 text-sm">
                        <span className="font-semibold">
                          {report.pmtReceiptDate?.split(" | ")[0]}
                        </span>
                        {(report.tradeStatus === "ARRIVED" ||
                          report.tradeStatus === "DISPATCHED") && (
                          <span
                            className={`ml-2 font-semibold text-xs text-white br-10 p-1 ${
                              report.tradeStatus === "ARRIVED"
                                ? "bg-primary"
                                : "bg-red"
                            }`}
                          >
                            {report.tradeStatus}
                          </span>
                        )}
                        <br />
                        <span className="color-sub-text">
                          {report.pmtReceiptDate?.split(" | ")[1] &&
                          moment(
                            report.pmtReceiptDate.split(" | ")[1],
                            "YYYY-MM-DD",
                            true
                          ).isValid()
                            ? moment(
                                report.pmtReceiptDate.split(" | ")[1]
                              ).format("DD-MMM-YYYY")
                            : "-"}
                        </span>{" "}
                      </td>
                      <td className="px-3 py-2">
                        <span className="font-semibold text-sm">
                          {report.item?.split(" | ")[0]} 
                          {report.item?.split(" | ")[1] ? ` | ${report.item.split(" | ")[1]}` : ""}
                        </span>
                        <br />
                        <span className="color-sub-text text-sm">
                          {report.item?.split(" | ")[2]} 
                          {report.item?.split(" | ")[3] ? ` | ${report.item.split(" | ")[3]}` : ""}
                        </span>
                      </td>
                      <td className="px-3 py-2 font-semibold">
                        {getCurrencyNumberWithOutSymbol(report.paidRcvd) || 0}
                      </td>
                      <td className="px-3 py-2 font-semibold">
                        {getCurrencyNumberWithOutSymbol(report.tobePaidRcvd) ||
                          0}
                      </td>
                      <td
                        className={`px-3 py-2 font-semibold ${
                          report.balance < 0 ? "color-red" : "color-primary"
                        }`}
                      >
                        {getCurrencyNumberWithOutSymbol(report.balance) || 0}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <tr style={{ border: "none" }}>
                <td
                  colSpan={6}
                  className="text-center py-4"
                  style={{ border: "none" }}
                >
                  <div className="flex items-center justify-center py-2">
                    <img src={NodataAvailable} alt="No data available" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Ledgers;

import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { FaRegPenToSquare, FaXmark } from "react-icons/fa6";
import {
  RadioButton,
  Select2,
  Input,
  Button,
  FileUploader,
} from "../../../../components/ui";
import Popover from "../../../../components/ui/popover";
import Calendar from "../../../../components/commons/calender";
import DocumentPreViewer from "../../../../components/commons/document-preview";
import PartnerInfo from "../../../../components/home/partner";
import useOrderView from "../../useOrderView";
import { getCurrencyNumberWithSymbol } from "../../../../helpers/get-currency-number";
import { TRootState } from "../../../../store";
import { maskAccountNumber } from "../../../../helpers/getText";
import usePayoutStep1 from "./usePayoutStep1";
import onoPayout from "../../../../assets/images/ono_payout.svg";
import { onoArkAccount } from "../../../../constants";

const PayoutStep1: FC<{
  traderId: number;
  tradeId: number;
  recordType?: string;
}> = ({ traderId, tradeId, recordType }) => {
  const {
    recordPayoutData,
    tradeDetail,
    setPaymentDate,
    setPaymentType,
    setPaymentMode,
    setComments,
    setAmount,
    setAdvance,
  } = usePayoutStep1();

  const numberToWords = (num: any) => {
    if (num === "" || num === undefined || num === null) return "";
    if (num === 0) return "Zero";

    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
      "",
      "Twentyone",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    let words = "";

    if (num >= 10000000) {
      const crorePart = Math.floor(num / 10000000);
      const remainder = num % 10000000;

      if (crorePart > 10 && crorePart < 20) {
        words += teens[crorePart - 10] + " Crore ";
      } else {
        words +=
          tens[Math.floor(crorePart / 10)] +
          " " +
          units[crorePart % 10] +
          " Crore ";
      }

      num = remainder;
    }

    if (num >= 100000) {
      const lakhPart = Math.floor(num / 100000);
      const remainder = num % 100000;

      if (lakhPart > 10 && lakhPart < 20) {
        words += teens[lakhPart - 10] + " Lakh ";
      } else {
        if (Math.floor(lakhPart / 10) > 0) {
          words += tens[Math.floor(lakhPart / 10)] + " ";
        }
        words += units[lakhPart % 10] + " Lakh ";
      }

      num = remainder;
    }

    if (num >= 1000) {
      const thousandPart = Math.floor(num / 1000);
      const remainder = num % 1000;

      if (thousandPart > 10 && thousandPart < 20) {
        // Handle teens
        words += teens[thousandPart - 10] + " Thousand ";
      } else {
        // Handle tens and units
        if (Math.floor(thousandPart / 10) > 0) {
          words += tens[Math.floor(thousandPart / 10)] + " ";
        }
        words += units[thousandPart % 10] + " Thousand ";
      }

      num = remainder;
    }

    if (num >= 100) {
      words += units[Math.floor(num / 100)] + " Hundred ";
      num %= 100;
    }

    if (num >= 11 && num <= 19) {
      words += teens[num - 10] + " ";
    } else {
      if (num >= 20) {
        words += tens[Math.floor(num / 10)] + " ";
        num %= 10;
      }
      words += units[num] + " ";
    }

    return words.trim();
  };

  const [openCalender, setOpenCalendar] = useState(false);
  const paymentTypeVal = recordType;
  // : recordPayoutData.partyId === tradeDetail?.destPartyId
  // ? "INWARD"
  // : recordPayoutData.partyId === tradeDetail?.srcPartyId
  // ? "OUTWARD"
  // : "";
  const { editAdvPay } = useSelector((state: TRootState) => ({
    editAdvPay: state.trades.editAdvancePayment,
  }));

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Or use any breakpoint you prefer
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        className="px-4 py-2 overflow-y-auto"
        style={{
          height: isMobile ? "calc(100vh - 20rem)" : "calc(100vh - 12rem)",
        }}
      >
        <span className="font-semibold text-sm">From</span>
        <div className="flex border rounded-md p-2">
          <div className="flex border-b md:border-none pb-2 md:pb-0">
            <img src={onoPayout} className="w-12 h-12 br-100" />
            <div className="ml-2 flex flex-col">
              <span className="font-semibold text-sm">
                {onoArkAccount.accountName}
              </span>
              <span className="text-sm font-light">
                {maskAccountNumber(onoArkAccount.accountNumber)}
              </span>
              <span className="text-sm font-light">
                {onoArkAccount.bankName}
              </span>
            </div>
          </div>
        </div>
        <div className="text-sm font-light">
          Balance Available:{" "}
          {getCurrencyNumberWithSymbol(
            recordPayoutData?.availableBalance,
            true
          )}
        </div>
        <div className="flex gap-4 mt-2">
          <div
            className={`${paymentTypeVal == "INWARD" ? "grow-[3]" : "w-[50%]"}`}
          >
            <span className="font-semibold text-sm">
              {paymentTypeVal == "INWARD" ? "Received From " : "Payable To "}
            </span>
            <div className="flex border rounded-md items-center gap-2 px-2 py-2">
              <RadioButton
                data={[{ label: "", value: tradeDetail?.srcPartyId || "" }]}
                pointer={{ label: "label", value: "value" }}
                value={recordPayoutData.partyId}
                onChange={(v) => {}}
              />
              <div className="flex">
                {paymentTypeVal == "INWARD" && (
                  <PartnerInfo
                    partyType="From"
                    name={tradeDetail?.destName || ""}
                    tradeName={tradeDetail?.destTradeName + "(Dest)" || ""}
                    profilePic={""}
                  />
                )}
                {paymentTypeVal == "OUTWARD" && (
                  <PartnerInfo
                    partyType="To"
                    name={tradeDetail?.srcName || ""}
                    tradeName={tradeDetail?.srcTradeName + "(Source)" || ""}
                    profilePic={""}
                  />
                )}
              </div>
            </div>
          </div>
          {paymentTypeVal != "INWARD" && tradeDetail?.payAcc != null && (
            <div className="w-[50%]">
              <span className="font-semibold text-sm">Fund Account ID</span>
              <div className="border rounded-md items-center gap-2 px-2 py-2">
                <div className="text-sm font-semibold">
                  {tradeDetail?.payAcc?.rzpFa}
                </div>
                <div className="text-xs font-medium">
                  {maskAccountNumber(tradeDetail?.payAcc?.actNum)}
                </div>
              </div>
            </div>
          )}
          {/* <div className="grow-[3]">
            <span className="font-semibold text-sm">Received From </span>
            <div
              className="flex border rounded-md items-center gap-2 px-2 py-2"
              onClick={() => {
                setrecordPayoutData({
                  ...recordPayoutData,
                  partyId: tradeDetail?.destPartyId || "",
                  paymentType: "INWARD",
                });
              }}
            >
              <RadioButton
                data={[{ label: "", value: tradeDetail?.destPartyId || "" }]}
                pointer={{ label: "label", value: "value" }}
                value={recordPayoutData.partyId}
                onChange={(v) => {}}
              />
              <div className="flex">
                <PartnerInfo
                  partyType="From"
                  name={tradeDetail?.destName || ""}
                  tradeName={tradeDetail?.destTradeName || ""}
                  profilePic={""}
                />
              </div>
            </div>
          </div> */}
        </div>
        <div className="flex gap-4 mt-4">
          <div className="relative grow-[3]">
            <Popover
              open={openCalender}
              element={
                <div>
                  <Input
                    label="Select Date"
                    onFocus={() => {
                      setOpenCalendar(true);
                    }}
                    value={moment(recordPayoutData.paymentDate).format(
                      "DD-MM-YYYY"
                    )}
                  />
                </div>
              }
              onClose={() => {
                setOpenCalendar(false);
              }}
            >
              <Calendar
                mode="single"
                initialDateRange={{}}
                onChange={({ date }: { date: string }) => {
                  setOpenCalendar(false);
                  setPaymentDate(date);
                }}
                currentDate={recordPayoutData.paymentDate}
                maxDate={moment().format("YYYY-MM-DD")}
                style={{ width: "18rem" }}
              />
            </Popover>
          </div>
          <div className="grow-[3] relative">
            {paymentTypeVal !== "" && tradeDetail && (
              <>
                <Input
                  type="number"
                  label={
                    paymentTypeVal === "OUTWARD"
                      ? "Final Payable "
                      : "Final Receivable "
                  }
                  value={
                    paymentTypeVal === "INWARD"
                      ? parseFloat(
                          (
                            Number(tradeDetail?.totalPayable) +
                            Number(tradeDetail?.destPf) -
                            Number(tradeDetail?.destDis)
                          ).toFixed(2)
                        )
                      : parseFloat(
                          (
                            Number(tradeDetail?.totalReceivable) -
                            Number(tradeDetail?.srcPf) +
                            Number(tradeDetail?.srcDis)
                          ).toFixed(2)
                        )
                  }
                />
                <FaRegPenToSquare className="absolute top-0 -right-0" />
              </>
            )}
          </div>
        </div>
        <div className="mt-4">
          {recordPayoutData?.paymentType != "" &&
          recordPayoutData?.paymentType == "ADVANCE" &&
          paymentTypeVal === "OUTWARD" &&
          tradeDetail?.reqAdv != null ? (
            <Input
              type="number"
              label={"Amount Paid"}
              value={recordPayoutData.requestAdv}
              onChange={(e) => {
                setAdvance(e.target.value);
              }}
            />
          ) : (
            <Input
              type="number"
              label={
                paymentTypeVal === "OUTWARD" ? "Amount Paid" : "Amount Received"
              }
              value={recordPayoutData.paidAmount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
          )}
          <p className="text-sm mt-1 color-primary">
            {" "}
            {numberToWords(recordPayoutData?.paidAmount || "")}{" "}
            {recordPayoutData?.paidAmount ? "Rupees Only" : ""}
          </p>
        </div>
        {paymentTypeVal !== "" && (
          <div className="flex gap-4 mt-3">
            <div className="grow-[1]">
              <div className="font-semibold text-sm"></div>
              <Input
                label={
                  paymentTypeVal === "OUTWARD"
                    ? "Paid Till date"
                    : "Received Till date"
                }
                value={
                  paymentTypeVal == "OUTWARD"
                    ? getCurrencyNumberWithSymbol(tradeDetail?.outwardPmt, true)
                    : getCurrencyNumberWithSymbol(tradeDetail?.inwardPmt, true)
                }
                disabled={true}
              />
              {/* <div className="text-sm font-medium">
                {paymentTypeVal == "OUTWARD" &&
                  getCurrencyNumberWithSymbol(tradeDetail?.outwardPmt, true)}
                {paymentTypeVal == "INWARD" &&
                  getCurrencyNumberWithSymbol(tradeDetail?.inwardPmt, true)}
              </div> */}
            </div>
            <div className="grow-[1]">
              <div className="font-semibold text-sm"></div>
              <Input
                label={
                  paymentTypeVal === "OUTWARD"
                    ? "Balance Payable"
                    : "Balance Receivable"
                }
                value={
                  paymentTypeVal === "INWARD"
                    ? getCurrencyNumberWithSymbol(
                        (Number(tradeDetail?.totalPayable) +
                          Number(tradeDetail?.destPf) -
                          Number(tradeDetail?.destDis) || 0) -
                          (tradeDetail?.inwardPmt || 0),
                        true
                      )
                    : getCurrencyNumberWithSymbol(
                        (Number(tradeDetail?.totalReceivable) -
                          Number(tradeDetail?.srcPf) +
                          Number(tradeDetail?.srcDis) || 0) -
                          (tradeDetail?.outwardPmt || 0),
                        true
                      )
                }
                disabled={true}
              />
            </div>
            <div className="grow-[1]">
              {paymentTypeVal == "OUTWARD" && (
                <Select2
                  value={recordPayoutData.paymentType}
                  lable="Payment Type "
                  options={[
                    {
                      label: "Transportation",
                      value: "TRANSPORTATION",
                    },
                    {
                      label: "Advance",
                      value: "ADVANCE",
                      disabled:
                        tradeDetail?.advanceRcvd != 0
                          ? editAdvPay
                            ? false
                            : true
                          : false,
                    },
                    {
                      label: "Partial Payment",
                      value: "PARTIAL_PAYMENT",
                    },
                    {
                      label: "Full Payment",
                      value: "FULL_PAYMENT",
                    },
                    {
                      label: "Final Payment",
                      value: "FINAL_PAYMENT",
                    },
                  ]}
                  starMark={true}
                  onChange={(selectedPaymentType) => {
                    setPaymentType(selectedPaymentType.value);
                  }}
                />
              )}
            </div>
          </div>
        )}
        {paymentTypeVal != "OUTWARD" && (
          <div className="relative flex mt-3 gap-4">
            {paymentTypeVal == "OUTWARD" && (
              <Select2
                value={recordPayoutData.paymentType}
                lable="Payment Type "
                options={[
                  {
                    label: "Transportation",
                    value: "TRANSPORTATION",
                  },
                  {
                    label: "Advance",
                    value: "ADVANCE",
                    disabled:
                      tradeDetail?.advanceRcvd != 0
                        ? editAdvPay
                          ? false
                          : true
                        : false,
                  },
                  {
                    label: "Partial Payment",
                    value: "PARTIAL_PAYMENT",
                    // disabled:
                    //   tradeDetail?.advanceRcvd != 0
                    //     ? editAdvPay
                    //       ? true
                    //       : false
                    //     : false,
                  },
                  {
                    label: "Full Payment",
                    value: "FINAL_PAYMENT",
                  },
                ]}
                starMark={true}
                onChange={(selectedPaymentType) => {
                  setPaymentType(selectedPaymentType.value);
                }}
                classNames="w-1/2"
              />
            )}
            {paymentTypeVal == "INWARD" && (
              <Select2
                value={recordPayoutData.paymentType}
                lable="Payment Type "
                options={[
                  {
                    label: "Transportation",
                    value: "TRANSPORTATION",
                  },
                  {
                    label: "Advance",
                    value: "ADVANCE",
                  },
                  {
                    label: "Partial Payment",
                    value: "PARTIAL_PAYMENT",
                  },
                  {
                    label: "Full Payment",
                    value: "FINAL_PAYMENT",
                  },
                ]}
                onChange={(selectedPaymentType) => {
                  setPaymentType(selectedPaymentType.value);
                }}
                classNames="w-1/2"
              />
            )}
            <Select2
              value={recordPayoutData.paymentMode}
              lable="Payment Mode "
              options={[
                {
                  label: "IMPS",
                  value: "IMPS",
                },
                {
                  label: "NEFT",
                  value: "NEFT",
                },
                {
                  label: "UPI",
                  value: "UPI",
                },
                {
                  label: "RTGS",
                  value: "RTGS",
                },
                {
                  label: "CASH",
                  value: "CASH",
                },
                {
                  label: "CHEQUE",
                  value: "CHEQUE",
                },
              ]}
              onChange={(selectedPaymentMode) => {
                setPaymentMode(selectedPaymentMode.value);
              }}
              classNames="w-1/2"
            />
          </div>
        )}
        {paymentTypeVal == "OUTWARD" && (
          <div className="mt-3">
            <div className="text-sm font-semibold">Payment Mode*</div>
            <div className="flex">
              <RadioButton
                displayType="horizontal"
                data={[
                  { label: "NEFT", value: "NEFT" },
                  { label: "RTGS", value: "RTGS" },
                  { label: "IMPS", value: "IMPS" },
                ]}
                value={recordPayoutData.paymentMode}
                pointer={{ label: "label", value: "value" }}
                onChange={(selectedPaymentMode) => {
                  setPaymentMode(selectedPaymentMode.toString());
                }}
                styles={{ display: "flex", gap: "1rem", paddingTop: "0.5rem" }}
              />
            </div>
            <div>
              {(recordPayoutData.paymentMode == "IMPS" ||
                recordPayoutData.paymentMode == "NEFT") && (
                <span className="text-sm font-semibold color-primary">
                  Rs. 1 Onwards
                </span>
              )}
              {recordPayoutData.paymentMode == "RTGS" && (
                <span className="text-sm font-semibold color-primary">
                  Rs. 2 Lacs Onwards
                </span>
              )}
            </div>
          </div>
        )}
        <div className="" style={{ paddingTop: "10px" }}>
          <div>
            <Input
              type="text"
              label={"Comments"}
              value={recordPayoutData.comments}
              onChange={(e) => {
                setComments(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default PayoutStep1;

import PartnerInfo from "../../../../components/partner-info";
import document from '../../../../assets/images/document.svg'
import { Button } from "../../../../components/ui";
import { TApplicationViewDetails, TBeneficiaryDetails } from "../../../../types";
import useBeneficiaryDetailsById from "../../../../hooks/useBeneficiaryDetailsById";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TRootState } from "../../../../store";
import Modal from "../../../../components/ui/modal";
import CloseLoanModal from "../Advance-Disbursal/CloseLoanModal";
import moment from "moment";
import { getLoanTypeName } from "../../../../helpers/getText";
import { getCurrencyNumberWithSymbol } from "../../../../helpers/get-currency-number";
const DisbursementDetails: React.FC<{

}> = ({ }) => {

    const { InitApplicationDetail, role } = useSelector(
        (state: TRootState) => ({
            InitApplicationDetail: state.loans.applicationViewDetails,
            role: state.auth.userInfo?.roleType,
        })
    );

    const [closeLoanModal, setCloseLoanModal] = useState(false);

    return (
        <>
            <div className="bg-light-primary px-3 py-2 mt-3 rounded-t-md">
                <span className="text-sm font-semibold">Disbursement Details</span>
            </div>

            <div className="bg-white px-3 py-4 border rounded-b-md">

                <div className="flex">
                    {/* <div
                        className="flex flex-col justify-center"
                        style={{ width: "100%" }}
                    >
                        <PartnerInfo
                            profilePic=""
                            name={InitApplicationDetail?.bwrName || ""}
                            shortName=""
                            partnerType={"CASH ID"}
                            partnerId={InitApplicationDetail?.cpId}
                            partnerMobile={InitApplicationDetail?.bwrMobile}
                            partyName={InitApplicationDetail?.partyName}
                            fromLoansParty={true}
                        />
                    </div> */}
                     <div
                        className="flex px-2 flex-col"
                        style={{ width: "100%" }}
                    >
                        <span className="text-sm">Loan Id</span>
                        <span className="font-semibold text-sm">
                            {InitApplicationDetail?.loanSmry?.loanId || "-"}
                        </span>
                    </div>
                    <div
                        className="flex px-2 flex-col"
                        style={{ width: "100%" }}
                    >
                        <span className="text-sm">Loan Type</span>
                        <span className="font-semibold text-sm">
                            {InitApplicationDetail?.loanSmry?.type || "-"}
                        </span>
                    </div>

                    <div
                        className="flex px-2 flex-col"
                        style={{ width: "100%" }}
                    >
                        <span className="text-sm">Loan Sequence</span>
                        <span className="font-semibold text-sm">
                            {InitApplicationDetail?.loanSmry?.loanSeq || "-"}
                        </span>
                    </div>

                    <div
                        className="flex flex-col items-center justify-center"
                        style={{ width: "100%" }}
                    >
                        <div className="flex cursor-pointer">
                            {/* <div>
                                <img src={document} alt="document" className="w-8" />
                            </div> */}
                            {/* <div className="color-blue text-sm py-2">Download Loan Receipt</div> */}
                        </div>

                    </div>
                </div>

                <div className="border-b px-2 py-2"></div>

                <div className="flex">
                    <div className="flex pt-2" style={{ width: "75%" }}>
                        <div
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "20%" }}
                        >
                            <span className="text-sm">Disbursement Date</span>
                            <span className="font-semibold text-sm">
                                {moment(InitApplicationDetail?.loanSmry?.createdOn).format("DD-MMM-YY | hh:mm:A")}
                            </span>
                        </div>
                        <span
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "20%" }}
                        >
                            {" "}
                            <span className="text-sm">Disbursed Amount</span>
                            <span className="font-semibold text-sm"> ₹{InitApplicationDetail?.loanSmry?.disbAmt}</span>
                        </span>
                        <div
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "20%" }}
                        >
                            <span className="text-sm">Account Holder Name</span>
                            <span className="font-semibold text-sm">{InitApplicationDetail?.payoutAcc?.actHolder}</span>
                        </div>
                    </div>
                </div>

                <div className="border-b px-2 py-2"></div>

                <div className="flex">
                    <div className="flex pt-2" style={{ width: "75%" }}>
                        <div
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "20%" }}
                        >
                            <span className="text-sm">Account Number</span>
                            <span className="font-semibold text-sm">
                                {InitApplicationDetail?.payoutAcc?.actNum}
                            </span>
                        </div>
                        <span
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "20%" }}
                        >
                            {" "}
                            <span className="text-sm">IFSC Code</span>
                            <span className="font-semibold text-sm">{InitApplicationDetail?.payoutAcc?.ifsc}</span>
                        </span>
                        <div
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "20%" }}
                        >
                            <span className="text-sm">Bank Name</span>
                            <span className="font-semibold text-sm">{InitApplicationDetail?.payoutAcc?.bank}</span>
                        </div>
                    </div>
                </div>

                <div className="border-b px-2 py-2"></div>

                <div className="flex">
                    <div className="flex pt-2" style={{ width: "100%" }}>
                        <div
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "20%" }}
                        >
                            <span className="text-sm">Interest Rate</span>
                            <span className="font-semibold text-sm">
                                {InitApplicationDetail?.loanSmry?.intrestRate}%
                            </span>
                        </div>
                        <span
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "20%" }}
                        >
                            {" "}
                            <span className="text-sm">Principal Amount</span>
                            <span className="font-semibold text-sm">₹{InitApplicationDetail?.loanSmry?.disbAmt}</span>
                        </span>
                        <div
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "20%" }}
                        >
                            <span className="text-sm">Tenure</span>
                            <span className="font-semibold text-sm">{InitApplicationDetail?.loanSmry?.tenure} Days</span>
                        </div>
                        <div
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "20%" }}
                        >
                            <span className="text-sm">Due Date</span>
                            <span className="font-semibold text-sm">
                                {moment(InitApplicationDetail?.loanSmry?.dueOn).format("DD-MMM-YYYY")}

                            </span>
                        </div>
                    </div>
                </div>

                <div className="border-b px-2 py-2"></div>

                <div className="flex">
                    <div className="flex pt-2" style={{ width: "100%" }}>
                        <div
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "25%" }}
                        >
                            <span className="text-sm">Repayment Amount</span>
                            <span className="font-semibold text-sm color-primary">
                                ₹{InitApplicationDetail?.loanSmry?.repayAmt}
                            </span>
                        </div>
                        <div
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "25%" }}
                        >
                            <span className="text-sm">UTR</span>
                            <span className="font-semibold text-sm">
                                {InitApplicationDetail?.payoutRes?.utr || '-'}
                            </span>
                        </div>
                        <div
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "25%" }}
                        >
                            {" "}
                            <span className="text-sm">Fees</span>
                            <span className="font-semibold text-sm">
                                {getCurrencyNumberWithSymbol(InitApplicationDetail?.payoutRes?.fees,true)}
                            </span>
                        </div>
                        <div
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "25%" }}
                        >
                            {" "}
                            <span className="text-sm">Tax</span>
                            <span className="font-semibold text-sm">
                                {getCurrencyNumberWithSymbol(InitApplicationDetail?.payoutRes?.tax,true)}
                            </span>
                        </div>
                        {/* <span
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "25%" }}
                        >
                            {" "}
                            <span className="text-sm">Repayment Date</span>
                            <span className="font-semibold text-sm color-primary">
                                {moment(InitApplicationDetail?.loanSmry?.dueOn).format("DD-MMM-YYYY")}
                            </span>
                        </span> */}
                    </div>
                </div>
                {/* <div className="flex">
                    <div className="flex pt-2" style={{ width: "100%" }}>
                       
                        <span
                            className="grow-[2] basis-0 px-2 flex flex-col"
                            style={{ width: "25%" }}
                        >  </span>
                    </div>
                </div> */}
                <div className="border-b px-2 py-2"></div>

                {InitApplicationDetail?.disbPrgs?.filter((i) => i.progStatus === "CLOSED").length == 0 && role === "ADMIN" && (
                    <div
                        className="grow-[2] basis-0 px-2 flex flex-col items-end py-4"
                        style={{ width: "100%" }}
                    >
                        <Button
                            text="Close Loan"
                            className=""
                            variant="outlined-primary"
                            onClick={() => {
                                setCloseLoanModal(true);
                            }}
                        />
                    </div>
                )}
            </div>


            <div>
                <Modal
                    open={closeLoanModal}
                    onClose={() => {
                        setCloseLoanModal(true);
                    }}
                    width="30vw"
                >
                    <>
                        {/* <ModalHeader title={"Advance Disbursal"} content={<></>} /> */}
                        <div className="px-8 py-8">
                            <CloseLoanModal
                                onClose={() => { setCloseLoanModal(false) }}
                            />
                        </div>
                    </>
                </Modal>
            </div>
        </>
    )
}
export default DisbursementDetails;

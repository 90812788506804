import React, { useEffect, useRef, useState } from "react";
import {
  BsArrowDownCircle,
  BsArrowUpCircle,
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";
import { Button, Slider } from "../../../components/ui";
import PartnerInfo from "../../../components/partner-info";
import useSmartboard from "../Applications/Select-bill/useSmartboard";
import { getCurrencyNumberWithOutSymbol } from "../../../helpers/get-currency-number";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getLoanPartyType } from "../../../helpers/getText";
const LoansAlerts = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState("0px");
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (!isCollapsed && contentRef.current) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setContentHeight("0px");
    }
  }, [isCollapsed]);
  const [steps, setSteps] = useState(0);

  const handleNext = () => {
    if (steps < smartBoard?.delayLoans.length! - 1) {
      setSteps(steps + 1);
    }
  };

  const handlePrevious = () => {
    if (steps > 0) {
      setSteps(steps - 1);
    }
  };

  const { smartBoard } = useSmartboard({});

  const navigate = useNavigate();

  return (
    <div className="py-4">
      <div className="bg-white border br-10 cursor-pointer">
        {" "}
        <div
          onClick={toggleCollapse}
          className="flex justify-between items-center px-2 py-2 border-b cursor-pointer"
        >
          <span>Overdue Payments Alerts</span>

          {isCollapsed ? <BsArrowDownCircle /> : <BsArrowUpCircle />}
        </div>
        <div className="bg-lightRed" style={{ width: "100%" }}>
          <div
            ref={contentRef}
            className="content "
            style={{
              maxHeight: contentHeight,
              overflow: "hidden",
              transition: "max-height 0.5s ease, opacity 0.5s ease",
              opacity: isCollapsed ? 0 : 1,
            }}
          >
            <div
              className="flex justify-center"
              style={{ width: "80%", margin: "0 auto" }}
            >
              <div
                className=" py-4 px-4 flex items-center"
                style={{ width: "100%" }}
              >
                <BsChevronLeft
                  className={"br-50 cursor-pointer bg-white w-8 h-8 border"}
                  onClick={() => {
                    handlePrevious();
                  }}
                  style={{ padding: "0.5rem", fontSize: "2rem" }}
                />
                <Slider step={steps} fullHeight={true}>
                  {
                    smartBoard?.delayLoans.map((loan, index) => (
                      <div
                        key={loan?.cpId} // Using cpId as a unique key
                        className={`bg-white border border-color-primary p-3 br-10 mx-3`}
                      >
                        <PartnerInfo
                          profilePic=""
                          name={loan?.partyName || ""}
                          shortName=""
                          partnerType={getLoanPartyType(loan?.partyType)}                         
                          partnerId={loan?.partyId}
                          partnerMobile={loan?.mobile || ""}
                          partyName=""
                          fromLoansParty={true}
                        />
                        <div className="border-b py-2"></div>
                        <div
                          className="flex gap-4 px-2 py-4"
                          style={{ width: "100%" }}
                        >
                          <div
                            className="grow-[2] basis-0 flex flex-col"
                            style={{ width: "20%" }}
                          >
                            <span className="text-sm">Application ID</span>
                            <p className="text-sm font-semibold color-blue">
                              {" "}
                              {loan.loanSeq ? (
                                <span>{loan.loanSeq} </span>
                              ) : (
                                <span>-</span>
                              )}
                            </p>
                          </div>
                          <div
                            className="grow-[2] basis-0 flex flex-col"
                            style={{ width: "20%" }}
                          >
                            <span className="text-sm">Cash Requested(₹)</span>
                            <p className="text-sm font-semibold">
                              {getCurrencyNumberWithOutSymbol(
                                loan.loanAmount
                              ) || "-"}
                            </p>
                          </div>
                          <div
                            className="grow-[2] basis-0 flex flex-col"
                            style={{ width: "20%" }}
                          >
                            <span className="text-sm">Days Overdue</span>
                            <p className="text-sm font-semibold">
                              {moment(loan.dueDate).format("DD-MMM-YYYY") ||
                                "-"}
                            </p>
                          </div>

                          <div className="flex gap-4 items-center">
                            {/* <Button
                              variant="outlined-primary"
                              className="ml-auto gap-1"
                              size="small"
                              onClick={() => { }}
                            >
                              Notify Them
                            </Button> */}

                            <Button
                              variant="outlined-primary"
                              className="ml-auto gap-1"
                              size="small"
                              onClick={() => {
                                navigate(`/application-view/${loan.disbId}`);
                              }}
                            >
                              View Details
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))!
                  }
                </Slider>
                <BsChevronRight
                  className={"br-50 cursor-pointer bg-white w-8 h-8 border"}
                  onClick={() => {
                    handleNext();
                  }}
                  style={{ padding: "0.5rem", fontSize: "2rem" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoansAlerts;

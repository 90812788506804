import moment from "moment";
import PartnerInfo from "../../../../components/partner-info";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
} from "../../../../helpers/get-currency-number";
import { TApplicationViewDetails } from "../../../../types";
import Comments from "../Review-comments/Comments";
import NodataAvailable from "../../../../assets/images/NodataAvailable.svg";
const CashHistory: React.FC<{ details: TApplicationViewDetails }> = ({
  details,
}) => {
  return (
    <>
      {/* <div style={{ width: "70%" }}> */}
      <div className="bg-light-primary px-3 py-2 mt-3 rounded-t-md">
        <span className="text-sm font-semibold">CASH History</span>
      </div>
      <div className="bg-white px-3 py-4 border ">
        <div
          className="grow-[2.5] basis-0 flex justify-between"
          style={{ flexBasis: "4rem", width: "100%" }}
        >
          <div
            className="grow-[2] basis-0 flex flex-col"
            style={{ flexBasis: "8rem", width: "60%" }}
          >
            <PartnerInfo
              profilePic=""
              name={details?.bwrName || ""}
              shortName={""}
              partnerType={"CASH ID "}
              partnerId={details?.cpId}
              partnerMobile={details?.bwrMobile ? details?.bwrMobile : ""}
              partyName={""}
              fromLoansParty={true}
            />
          </div>
          <div
            className="grow-[2.5] basis-0 flex px-2 flex-col"
            style={{ width: "20%" }}
          >
            <span className="text-sm">Total Disbursement</span>
            <span className="font-semibold text-sm">
              {getCurrencyNumberWithSymbol(details?.disbAmt) || "-"}
            </span>
          </div>

          <div
            className="grow-[2.5] basis-0 flex px-2 flex-col"
            style={{ width: "20%" }}
          >
            <span className="text-sm">Total Recovery</span>
            <span className="font-semibold text-sm">
              {" "}
              {getCurrencyNumberWithSymbol(details?.recoveredAmt) || "-"}
            </span>
          </div>
          <div
            className="grow-[2.5] basis-0 flex px-2 flex-col"
            style={{ width: "20%" }}
          >
            <span className="text-sm">Total Overdue</span>
            <span className="font-semibold text-sm">
              {" "}
              {getCurrencyNumberWithSymbol(details?.overDueAmt) || "-"}
            </span>
          </div>
        </div>
        <div className="border-b px-2 py-1"></div>
        <div className="flex px-2 py-4">
          <div
            className="grow-[3] flex justify-between"
            style={{ width: "100%" }}
          >
            {/* Main Text Section */}
            <div
              className="grow-[3] basis-0 px-2 flex flex-col"
              style={{ width: "80%" }}
            >
              <span className="text-sm">
                The Loan History for the borrower is shown below, with loans to
                the beneficiary:{" "}
                <span className="font-semibold text-sm">
                  {details?.partyName}.
                </span>
              </span>
            </div>

            {/* Total Loans Section */}
            <div
              className="grow basis-0 px-2 flex flex-col text-center"
              style={{ width: "10%" }}
            >
              <span className="text-sm">Total Loans</span>
              <span className="text-blue-600 text-sm font-semibold">
                {details?.totalLoans}
              </span>
            </div>

            {/* Active Loans Section */}
            <div
              className="grow basis-0 px-2 flex flex-col text-center"
              style={{ width: "10%" }}
            >
              <span className="text-sm">Active Loans</span>
              <span className="text-blue-600 text-sm font-semibold">
                {details?.activeLoans}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-auto bg-white">
        <table className="min-w-full border-collapse border">
          <thead>
            <tr className="bg-light-primary">
              <th className="px-2 py-2 border text-sm font-normal ">#</th>
              <th className="px-2 py-2 border text-sm font-normal text-left">
                Loan ID
              </th>
              <th className="px-2 py-2 border text-sm font-normal text-left">
                Disbursed(₹)
              </th>
              <th className="px-2 py-2 border text-sm font-normal text-left">
                Total Recovered (₹)
              </th>
              <th className="px-2 py-2 border text-sm font-normal text-left">
                Repayment Date
              </th>
              <th className="px-2 py-2 border text-sm font-normal text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {details?.cashHistory && details.cashHistory.length > 0 ? (
              details?.cashHistory?.map((loan, i) => (
                <tr key={loan.loanId} className="cursor-pointer">
                  <td className="px-2 py-2 border text-sm text-center">
                    {i + 1}
                  </td>
                  <td className="px-2 py-2 border text-sm">
                    <div className="color-blue text-sm font-medium">
                      {loan.loanId}
                    </div>
                    <div className="text-sm">{moment(loan?.date).format("DD-MMM-YY")}</div>
                  </td>
                  <td className="px-2 py-2 border text-sm font-semibold">
                    {getCurrencyNumberWithOutSymbol(loan.disbAmt) || "0"}
                  </td>
                  <td className="px-2 py-2 border color-primary text-sm font-semibold">
                    {getCurrencyNumberWithOutSymbol(loan.repaidAmt) || "0"}
                  </td>
                  <td className="px-2 py-2 border text-sm font-semibold">
                    {moment(loan.dueDate).format("DD-MMM-YYYY")}
                  </td>
                  <td className="px-2 py-2 border color-red text-sm font-medium">
                    {loan.loanStatus}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center py-4">
                  <div className="flex items-center justify-center py-2">
                    <img src={NodataAvailable} alt="image" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {(details?.disbStatus == "APPROVED" || details?.disbStatus == "DISBURSED") && (
        <div>
          <Comments data={details} type="APPROVED" />
        </div>
      )}
    </>
  );
};
export default CashHistory;

import { useEffect, useState } from "react";
import useAxiosClient from "./useAxiosClient";
import { TLedgerReport } from "../types/index";
import { useSelector } from "react-redux";
import { TRootState } from "../store";
import { NonNullExpression } from "typescript";

const useTradeLedger = () => {
  const { handleGetCall } = useAxiosClient();

  const { traderId, adminId } = useSelector((state: TRootState) => ({
    traderId: state.trades.traderId,
    adminId: state.auth.adminId,
  }));

  const { traderType } = useSelector((state: TRootState) => ({
    traderType: state.trades.traderType,
  }));

  const [ledgerDetails, setLedgerDetails] = useState<TLedgerReport | null>();

  useEffect(() => {
    handleGetCall<TLedgerReport>(
      `admin/trade-platform/trades/tradeLedger/adminId/${adminId}/traderId/${traderId}/type/${traderType}`
    ).then(({ data }) => {
      if (data) {
        setLedgerDetails(data);
      } else {
        setLedgerDetails(null);
      }
    });
  }, []);
  return {
    ledgerDetails,
  };
};
export default useTradeLedger;

import { useNavigate, useParams } from "react-router-dom";
import noData from "../../../assets/images/NodataAvailable.svg";
import { Button, Input, PanelSlider, Popover } from "../../../components/ui";
import RecordPayment from "../../../pages/trade-detail/record-payment";
import { FC, useEffect, useRef, useState } from "react";
import cancelIcon from "../../../assets/images/close.svg";
import usePayNotifications from "../../../hooks/usePaymentNotifications";
import PaymentHistory from "../../../pages/trade-detail/payment-history";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../store";
import {
  getPaymentHistory,
  updateNotifiTradeId,
  updateNotifiTraderId,
  updatePaymentUpdateStatus,
  UpdateTradePaymentActiveTab,
  UpdateTradeTypeActiveTab,
} from "../../../reducers/tradeSlice";
import DocumentPreViewer from "../../../components/commons/document-preview";
import useOrderView from "../../../pages/trade-detail/useOrderView";

const Notifications: FC<{
  status?: boolean;
  modalClose?: any;
  traderId?: number;
  tradeId?: number;
}> = ({ status, modalClose, traderId, tradeId }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notifications } = usePayNotifications({ status: status });
  const { userId, roleType, notificationTraderId, notificationTradeId } =
    useSelector((state: TRootState) => ({
      userId: state.auth.userInfo.userId,
      roleType: state.auth.userInfo.roleType,
      notificationTraderId: state.trades.notificationTraderId,
      notificationTradeId: state.trades.notificationTradeId,
    }));
  const [openRecordPayment, setOpenRecordPayment] = useState(false);
  const [openPaymenHistory, setOpenPaymenHistory] = useState(false);
  const [id, setTradeID] = useState(0);

  const { getTradeDetail } = useOrderView({
    traderId: notificationTraderId,
    tradeId: notificationTradeId,
  });

  useEffect(() => {
    if (notificationTradeId > 0 && notificationTraderId > 0) {
      getTradeDetail();
    }
  }, [notificationTradeId]);

// Close PanelSlider on outside click
const panelRef = useRef<HTMLDivElement | null>(null);

useEffect(() => {
  const handleClickOutside = (event: MouseEvent) => {
    if (
      panelRef.current &&
      !panelRef.current.contains(event.target as Node)
    ) {
      setOpenRecordPayment(false);
      setOpenPaymenHistory(false);
    }
  };
  if (openRecordPayment || openPaymenHistory) {
    document.addEventListener("mousedown", handleClickOutside);
  }
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [openRecordPayment, openPaymenHistory]);
  
  return (
    <>
      {notifications.length > 0
        ? notifications.map((item, i) => (
            <div className="flex border gap-4 items-center px-4 py-4  br-10">
              {item.imgs.length > 0 && (
                <div>
                  {item.imgs[0].url?.includes(".pdf") ? (
                    <iframe
                      src={item.imgs[0].url}
                      className="h-full w-full"
                      style={{
                        pointerEvents: "none",
                        width: "100px",
                        height: "100px",
                      }}
                    ></iframe>
                  ) : (
                    <img src={item.imgs[0].url} className="w-24 h-24" alt="" />
                  )}
                </div>
              )}
              <div className="text-sm ">
                <p className="font-semibold">
                  New Payment has been recorded by Destination Trader
                </p>
                <span className="">
                  Payment ID:{" "}
                  <span className="color-blue font-medium">{item.pmtId}</span> |
                </span>
                <span className="">
                  Trade ID:{" "}
                  <span className="color-blue font-medium">
                    {item?.tradeId}
                  </span>{" "}
                  |
                </span>
                <span className="">
                  Trader Name:{" "}
                  <span className="font-medium">{item.destTradeNAme}</span>
                </span>
                <br />
                <span className="flex gap-1">
                  <span className="flex">
                    <span className="color-sub-text">Amount Received: </span>
                    <span className="font-medium">{item.amount} | </span>
                  </span>

                  <span className="flex">
                    <span className="color-sub-text"> Payment Mode: </span>
                    <span className="font-medium">{item.pmtMode} | </span>
                  </span>
                  <span className="flex">
                    <span className="font-medium">{item.paymentType}</span>
                  </span>
                </span>
                <div className="flex gap-4 py-2 bg-white">
                  <div>
                    <Button
                      text="VIEW"
                      className=""
                      variant="primaryLight"
                      onClick={() => {
                        navigate(
                          `/trade-detail/${item?.traderId}/${item.tradeId}?tab=OPEN`
                        );
                        modalClose(false);
                        dispatch(UpdateTradeTypeActiveTab("payment_details"));
                        dispatch(UpdateTradePaymentActiveTab("inward_payment"));
                      }}
                    ></Button>
                  </div>
                  {roleType === "ADMIN" && (
                    <div className="">
                      <Button
                        text="EDIT RECEIPT"
                        className=""
                        onClick={() => {
                          setOpenPaymenHistory(true);
                          setTradeID(item.tradeId);
                          dispatch(
                            updateNotifiTraderId({
                              notificationTraderId: item.traderId,
                            })
                          );
                          dispatch(
                            updateNotifiTradeId({
                              notificationTradeId: item.tradeId,
                            })
                          );
                          dispatch(
                            getPaymentHistory({
                              paymentHistoryObj: item,
                            })
                          );
                          dispatch(
                            updatePaymentUpdateStatus({
                              paymentUpdateStatus: true,
                            })
                          );
                          modalClose(false);
                          navigate(
                            `/trade-detail/${item?.traderId}/${item.tradeId}?tab=OPEN`
                          );
                          dispatch(UpdateTradeTypeActiveTab("payment_details"));
                          dispatch(UpdateTradePaymentActiveTab("inward_payment"));
                        }}
                      ></Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        : "No Data Available"}

      <PanelSlider
        direction="right_to_left"
        open={openPaymenHistory}
        onClose={() => {
          setOpenPaymenHistory(false);
        }}
        style={{ width: "40vw" }}
      >
        <div className="py-6 px-4 border-b flex items-center justify-between panel-content"
        ref={panelRef}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        >
          <div>
            <h2> Payment History</h2>
          </div>
          <div>
            <img
              src={cancelIcon}
              className="cursor-pointer"
              onClick={() => {
                setOpenPaymenHistory(false);
              }}
            />
          </div>
        </div>

        {openPaymenHistory === true && (
          <PaymentHistory
            traderId={notificationTraderId}
            tradeId={id}
            onClose={(paymentDone: boolean) => {
              setOpenPaymenHistory(false);
              if (paymentDone) {
                // setActiveTab("payment_details");
                // setActivePaymentTab("outward_payment");
              }
            }}
            onEdit={() => {
              setOpenPaymenHistory(false);
              setOpenRecordPayment(true);
            }}
            role={roleType}
          />
        )}
      </PanelSlider>

      <PanelSlider
        direction="right_to_left"
        open={openRecordPayment}
        onClose={() => {
          setOpenRecordPayment(false);
        }}
        style={{ width: "40vw" }}
      >
        <div className="py-6 px-4 border-b flex items-center justify-between">
          <div>
            <h2>Record Payment</h2>
          </div>
          <div>
            <img
              src={cancelIcon}
              className="cursor-pointer"
              onClick={() => {
                setOpenRecordPayment(false);
              }}
              alt=""
            />
          </div>
        </div>

        {openRecordPayment === true && (
          <RecordPayment
            traderId={notificationTraderId}
            tradeId={id}
            //  recordType={recordFrom}
            onClose={(paymentDone: boolean) => {
              setOpenRecordPayment(false);
              if (paymentDone) {
                //   // setActiveTab("payment_details");
                //   // setActivePaymentTab("outward_payment");
              }
            }}
          />
        )}
      </PanelSlider>
    </>
  );
};
export default Notifications;

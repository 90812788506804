import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import DeviceLayout from "./layout";
import AuthLayout from "./layout/auth-layout";
import PageLayout from "./layout/page-layout";
import NotFound from "./pages/not-found";
import "./App.css";
import Home from "./pages/home";
import TradeDetail from "./pages/trade-detail";
import Myprofile from "./pages/my-profile";
import Customer from "./pages/customer/Customer";
import AllDetails from "./pages/customer/CustomerDetails/AllDetails";
import Loans from "./pages/loans";
import ApplicationView from "./pages/loans/Applications/application-view";
import BorrowerDetails from "./pages/customer/BorrowerDetails";
import BeneficiaryDetails from "./pages/customer/BorrowerDetails/benificiary-details";
import SupplyPostView from "./pages/supply/supply-post-details";
import DemandPostView from "./pages/demand/demand-post-details";
import PostDetails from "./pages/supply/post-details";
import Reports from "./pages/reports";
import DebitCreditReports from "./pages/reports/debitCreditReports";
import OpenTradesPaymentReports from "./pages/reports/OpenTradesPaymentReports";
import AllTradeDetailReports from "./pages/reports/AllTradeDetailReports";
import ExcessPaymentReport from "./pages/reports/ExcessPaymentReport";
function App() {
  return (
    <DeviceLayout>
      <AuthLayout>
        <PageLayout>
          <Suspense fallback={<>Loading...</>}>
            <Routes>
              <Route path="/" element={<Customer />} />
              <Route
                path="/trade-detail/:traderId/:tradeId"
                element={<TradeDetail />}
              />
              <Route path="/my-profile" element={<Myprofile />} />
              <Route path="/customers" element={<Customer />} />
              <Route path="/allDetails/:traderId" element={<AllDetails />} />
              <Route path="/trades" element={<Home />} />
              <Route path="/loans" element={<Loans />} />
              <Route
                path="/borrower-detail/:cpId/:type"
                element={<BorrowerDetails />}
              />
              <Route
                path="/beneficiary-detail/:cpId/:appId/:type"
                element={<BeneficiaryDetails />}
              />
              <Route
                path="/application-view/:applicationId"
                element={<ApplicationView />}
              />
              <Route
                path="/supply-details/:postId"
                element={<SupplyPostView />}
              />
              <Route
                path="/demand-details/:postId"
                element={<DemandPostView />}
              />
              <Route path="/post-details" element={<PostDetails />} />
              <Route path="/reports" element={<Reports />}>
                <Route
                  path="/reports/debit-credit-reports"
                  element={<DebitCreditReports />}
                />
                <Route path="/reports/payment-receipts" element={<OpenTradesPaymentReports/>}/>
                <Route path='/reports/allTradeDetail-Reports' element={<AllTradeDetailReports/>}/>
                <Route path='/reports/ExcessPayment-Reports' element={<ExcessPaymentReport/>}/>
              </Route>

              {/* <Route path="/allDetails/:traderId/:tradeId" element={< TradeDetail/>} /> */}
            </Routes>
          </Suspense>
        </PageLayout>
      </AuthLayout>
    </DeviceLayout>
  );
}

export default App;

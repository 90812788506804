import React, { FC } from "react";
import { Button, Input } from "../../components/ui";
import otp_img from "../../assets/images/otp_img.svg";
import { TAddPayoutDetails } from "../../types";
const OtpVerification: FC<{
  onClose?: () => void;
  onSubmit: any;
  onVerifyOtp: any;
  sendOtp: any;
  setOtpVal: any;
  otpVal: string;
  addPayoutDetails: TAddPayoutDetails;
  fromAddAccount: boolean;
  updatePayoutAccount?: any;
  selectedPayout?: any;
}> = ({
  onClose,
  onSubmit,
  onVerifyOtp,
  sendOtp,
  setOtpVal,
  otpVal,
  addPayoutDetails,
  fromAddAccount,
  updatePayoutAccount,
  selectedPayout,
}) => {
  console.log(addPayoutDetails, "otpVerificationModal");
  return (
    <div>
      <div className="text-center py-2 mt-2">
        <img
          src={otp_img}
          className="h-8 w-8 flex justify-center items-center mx-auto mb-2"
        />
        <span className="text-sm font-medium">
          Please enter the OTP to add Account
        </span>
        <div className="flex justify-between py-2 mt-4">
          <div className="flex flex-col grow-[3] mr-5">
            <div className="flex justify-between">
              <div className="flex text-sm font-semibold">
                Enter OTP <span className="color-red">*</span>
              </div>
              {/* {recordPayoutData.refId.length == 0 && ( */}
              <div
                className="flex flex-col text-sm font-medium color-blue cursor-pointer"
                onClick={() => {
                  sendOtp(fromAddAccount ? "ADD_ACCOUNT" : "");
                }}
              >
                Resend OTP
              </div>
              {/* )} */}
            </div>
            <div>
              <Input
                type="number"
                label={""}
                value={otpVal}
                onChange={(e) => {
                  setOtpVal(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col grow-[1]">
            <span className="h-[25px]">{""}</span>
            <Button
              variant={`contained`}
              disabled={addPayoutDetails.refId.length > 0}
              onClick={() => {
                onVerifyOtp(onClose);
              }}
              styles={{ width: "100%" }}
            >
              {addPayoutDetails.refId.length > 0 ? "VERIFIED" : "VERIFY"}
            </Button>
          </div>
        </div>
        {/* {addPayoutDetails.isApiCall && ( */}
        <div className="pt-10">
          {" "}
          <Button
            disabled={addPayoutDetails.refId.length == 0}
            variant={`contained`}
            onClick={() => {
              fromAddAccount
                ? onSubmit(onClose)
                : updatePayoutAccount(
                    selectedPayout?.id!,
                    selectedPayout?.status === 1 ? 0 : 1,
                    onSubmit
                  );
            }}
            styles={{ width: "100%" }}
          >
            SUBMIT
          </Button>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default OtpVerification;

import useExcessPaymentReport from "./useExcessPaymentReport";
import moment from "moment";
import { getCurrencyNumberWithOutSymbol } from "../../helpers/get-currency-number";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
import { FaSearch } from "react-icons/fa";
import { Input } from "../../components/ui";
import { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import excel from "../../assets/images/excel.svg";
import Loading from "../../assets/images/loading.gif";
import React from "react";
import { TExcessPaymentReport } from "../../types";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';  
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExcessPaymentReport = () => {
  const { allExcessPaymentReport, isLoading } = useExcessPaymentReport();

  const [filteredTraders, setFilteredTraders] = useState<any[]>([]);

  const [searchText, setSearchText] = useState("");
  const filterTraders = () => {
    let filtered = allExcessPaymentReport;
    const trimmedSearchText = searchText.trim().toLowerCase();
    filtered = filtered?.filter(
      (option: any) =>
        option.tradeId &&
        option.tradeId.toString().toLowerCase().includes(trimmedSearchText)
    );
    setFilteredTraders(filtered);
  };

  const data = filteredTraders.map((item, i) => ({
    tradeId: item.tradeId,
    srcTradeName: item.srcTradeName,
    destTradeName: item.destTradeName,
    tradeType: item.tradeType,
    tradeStatus: item.tradeStatus,
    netPayable: item.netPayable,
    netReceivable: item.netReceivable,
    tradeRecon: item.tradeRecon,
    tradePmtsOutward: item?.tradePmts
      ?.filter((payment: any) => payment?.creditDebit === "OUTWARD")
      .map((payment: any) => ({
        payoutId: payment?.payoutId,
        paid: payment?.paid,
        pmtType: payment?.pmtType,
        pmtDate: payment?.pmtDate,
        pmtMode: payment?.pmtMode,
        utr: payment?.utr,
        desc: payment?.desc,
        amount: payment?.amount,
        status: payment?.status,
      })),
    srcRecon: item?.srcRecon,
    tradePmtsInward: item?.tradePmts
      ?.filter((payment: any) => payment?.creditDebit === "INWARD")
      .map((payment: any) => ({
        payoutId: payment?.payoutId,
        paid: payment?.paid,
        pmtType: payment?.pmtType,
        pmtDate: payment?.pmtDate,
        pmtMode: payment?.pmtMode,
        utr: payment?.utr,
        desc: payment?.desc,
        amount: payment?.amount,
        status: payment?.status,
      })),
    destRecon: item?.destRecon,
  }));

  const handleExport = () => {
    const headers = [
      "Trade Id",
      "Source Trade Name",
      "Destination Trade Name",
      "Trade Type",
      "Trade Status",
      "Final Source Payable",
      "Final Destination Receivable",
      "Recon Amount",
      "",
      "Details Type",
      "Payout ID",
      "Paid Till Date",
      "Payment Type",
      "Payment Date",
      "Mode",
      "UTR",
      "Description",
      "Amount",
      "Status",
    ];

    const exportData: any[] = [headers];

    data.forEach((row) => {
      exportData.push([
        row.tradeId,
        row.srcTradeName,
        row.destTradeName,
        row.tradeType,
        row.tradeStatus,
        row.netPayable,
        row.netReceivable,
        row.tradeRecon,
        "",
        "Trade Details",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);

      row.tradePmtsOutward?.forEach((sourceDetail: any) => {
        exportData.push([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Source Details",
          sourceDetail.payoutId || "",
          sourceDetail.paid || "",
          sourceDetail.pmtType || "",
          sourceDetail.pmtDate || "",
          sourceDetail.pmtMode || "",
          sourceDetail.utr || "",
          sourceDetail.desc || "",
          sourceDetail.amount || "",
          sourceDetail.status || "",
        ]);
      });

      row.tradePmtsInward?.forEach((destDetail: any) => {
        exportData.push([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Destination Details",
          destDetail.payoutId || "",
          destDetail.paid || "",
          destDetail.pmtType || "",
          destDetail.pmtDate || "",
          destDetail.pmtMode || "",
          destDetail.utr || "",
          destDetail.desc || "",
          destDetail.amount || "",
          destDetail.status || "",
        ]);
      });

      exportData.push(new Array(headers.length).fill(""));
    });

    if (exportData.length > 1) {
      const worksheet = XLSX.utils.aoa_to_sheet(exportData);

      worksheet["!merges"] = [{ s: { r: 0, c: 9 }, e: { r: 0, c: 18 } }];

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");
      XLSX.writeFile(workbook, `Open Trade Summary Report.xlsx`);
    } else {
      toast.error("No data available to export to Excel.");
    }
  };
  useEffect(() => {
    filterTraders();
  }, [searchText, allExcessPaymentReport]);

  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const toggleRow = (tradeId: string) => {
    setExpandedRow(expandedRow === tradeId ? null : tradeId);
  };

  const [totals, setTotals] = useState({
    totalDestinationPayments: 0,
    totalSourcePayments: 0,
  });

  const calculateSrcTotal = (payment: TExcessPaymentReport) => {
    const totalSourcePayments = payment?.tradePmts
      .filter((pmt) => pmt.creditDebit === "OUTWARD")
      .reduce((sum, pmt) => sum + pmt.amount, 0);
    return totalSourcePayments;
  };

  const calculateDestTotal = (payment: TExcessPaymentReport) => {
    const totalDestinationPayments = payment?.tradePmts
      .filter((pmt) => pmt.creditDebit === "INWARD")
      .reduce((sum, pmt) => sum + pmt.amount, 0);
    return totalDestinationPayments;
  };

  return (
    <>
      <div className="flex justify-between pb-3">
        <div
          className="relative border border-gray-300 rounded focus-within:border-green-500 focus-within:shadow-lg transition-all duration-300"
          style={{
            width: "30%",
          }}
        >
          <FaSearch
            className="absolute color-primary"
            size={15}
            style={{ top: "14px", left: "6px" }}
          />
          <Input
            type="text"
            palceholder="Search by Trade Id"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            inputStyle={{ textIndent: "22px", padding: "8px 5px" }}
          />
        </div>
        <div
          onClick={() => {
            handleExport();
          }}
          className="text-sm cursor-pointer flex items-center justify-center br-10 gap-2 bg-white"
          style={{
            padding: "8px 5px",
          }}
        >
          <img src={excel} alt="img" className="" style={{ width: "25px" }} />{" "}
          <span>Export to Excel</span>
        </div>
      </div>

      <div
        className="overflow-y-auto overflow-x-auto"
        style={{ height: "calc(100vh - 12rem)" }}
      >
        <table className="w-full border border-gray-300">
          <thead className=" sticky top-0 bg-light-primary z-10">
            <tr className="text-left text-sm bg-white bg-light-primary">
              <th className="p-3 border border-gray-300">#</th>
              <th className="p-3 border border-gray-300">Trade ID</th>
              <th className="p-3 border border-gray-300">Source Trade Name</th>
              <th className="p-3 border border-gray-300">Dest Trade Name</th>
              <th className="p-3 border border-gray-300">Trade Type</th>
              <th className="p-3 border border-gray-300">Trade Status</th>
              <th className="p-3 border border-gray-300">
                Final Source Payable (₹)
              </th>
              <th className="p-3 border border-gray-300">
                Final Dest Receivable (₹)
              </th>
              {/* <th className="p-3 border border-gray-300">Recon Amount (₹)</th> */}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={100} className="md:text-center p-0">
                  <div className="bg-white" style={{ paddingLeft: "400px" }}>
                    <img src={Loading} alt="loading" className="w-16 h-16" />
                  </div>
                </td>
              </tr>
            ) : filteredTraders.length > 0 ? (
              filteredTraders.map((trade, index) => (
                <React.Fragment key={trade.tradeId}>
                  <tr
                    className={`text-sm cursor-pointer ${
                      expandedRow === trade.tradeId ? "bg-gray-200" : "bg-white"
                    }`}
                    onClick={() => toggleRow(trade.tradeId)}
                    style={{
                      transition: "all 0.4s ease",
                    }}
                  >
                    <td className="p-3 border border-gray-300">{index + 1}</td>
                    <td className="p-3 border border-gray-300 color-blue">
                      {trade.tradeId}
                    </td>
                    <td className="p-3 border border-gray-300">
                      {trade.srcTradeName}
                    </td>
                    <td className="p-3 border border-gray-300">
                      {trade.destTradeName}
                    </td>
                    <td className="p-3 border border-gray-300">
                      {trade.tradeType}
                    </td>
                    <td className="p-3 border border-gray-300">
                      {trade.tradeStatus}
                    </td>
                    <td className="p-3 border border-gray-300 color-red">
                      {getCurrencyNumberWithOutSymbol(trade.netPayable) || 0}
                    </td>
                    <td className="p-3 border border-gray-300 flex justify-between items-center color-primary">
                      {getCurrencyNumberWithOutSymbol(trade.netReceivable) || 0}
                      <span className="flex items-center justify-center rounded-full border border-gray text-black p-1 w-6 h-6">{expandedRow === trade.tradeId ? <ExpandLessIcon/> : <ExpandMoreIcon/>}</span>
                    </td>
                  </tr>

                  {expandedRow === trade.tradeId && (
                    <>
                      {/* Source Details Section */}
                      <tr className="bg-white">
                        <td
                          colSpan={11}
                          className="border-b-0"
                          style={{ borderBottom: "1px solid white" }}
                        >
                          <table className="rounded-t-md shadow-md">
                            <thead className="rounded-t-md">
                              <tr>
                                <th
                                  colSpan={9}
                                  className="p-3 text-sm bg-red-tint border-b-gray-400"
                                >
                                  Source Details
                                </th>
                              </tr>
                              <tr className=" text-sm bg-red-tint">
                                {[
                                  "Payout ID",
                                  "Payment Type",
                                  "Payment Date",
                                  "Mode",
                                  "UTR",
                                  "Description",
                                  "Amount",
                                  "Status",
                                ].map((header) => (
                                  <th
                                    key={header}
                                    className="p-3 border border-gray-300"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {trade.tradePmts &&
                              trade.tradePmts.filter(
                                (payment: any) =>
                                  payment.creditDebit === "OUTWARD"
                              ).length > 0 ? (
                                trade.tradePmts
                                  .filter(
                                    (payment: any) =>
                                      payment.creditDebit === "OUTWARD"
                                  )
                                  .map((detail: any, index: number) => (
                                    <tr
                                      key={index}
                                      className="text-sm bg-white"
                                    >
                                      <td className="p-3 border border-gray-300">
                                        {detail.payoutId}
                                      </td>
                                      <td className="p-3 border border-gray-300">
                                        {detail.pmtType || "-"}
                                      </td>
                                      <td className="p-3 border border-gray-300">
                                        {detail.pmtDate
                                          ? moment(detail.pmtDate).format(
                                              "DD-MMM-YYYY"
                                            )
                                          : "-"}
                                      </td>
                                      <td className="p-3 border border-gray-300">
                                        {detail.pmtMode || "-"}
                                      </td>
                                      <td className="p-3 border border-gray-300">
                                        {detail.utr || "-"}
                                      </td>
                                      <td className="p-3 border border-gray-300">
                                        {detail.desc || "-"}
                                      </td>
                                      <td className="p-3 border border-gray-300 color-red border-b-gray-400">
                                        {getCurrencyNumberWithOutSymbol(
                                          detail.amount
                                        ) || 0}
                                      </td>
                                      <td className="p-3 border border-gray-300">
                                        {detail.status}
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr className="bg-white">
                                  <td
                                    colSpan={9}
                                    className="p-3 text-center border border-gray-300"
                                  >
                                    No Source Details Available
                                  </td>
                                </tr>
                              )}

{trade?.tradePmts?.filter(
                              (payment: any) =>
                                payment.creditDebit === "OUTWARD"
                            ).length > 0 && (
                              <>
                               <tr>
                               <td
                                  colSpan={6}
                                  className="p-3 border border-gray-300 text-right text-sm font-semibold"
                                >
                                  Total Amount
                                </td>
                                <td className="p-3 border border-gray-300 color-red text-sm font-semibold">
                                  {getCurrencyNumberWithOutSymbol(
                                    calculateSrcTotal(trade)
                                  )}
                                </td>
                                <td colSpan={2}></td>
                               </tr>
                                

                               <tr>
                                <td
                                  colSpan={6}
                                  className="p-3 border border-gray-300 text-right text-sm font-semibold"
                                >
                                  Final Source Payable
                                </td>
                                <td className="p-3 border border-gray-300 color-red text-sm font-semibold">
                                {getCurrencyNumberWithOutSymbol(
                                    trade.netPayable
                                  ) || 0}
                                </td>
                                <td colSpan={2}></td>
                                </tr>


                                <tr>
                                <td
                                  colSpan={6}
                                  className="p-3 border border-gray-300 text-right text-sm font-semibold"
                                >
                                   Reconciliation Amount
                                </td>
                                <td className="p-3 border border-gray-300 color-red text-sm font-semibold">
                                {getCurrencyNumberWithOutSymbol(
                                    trade.srcRecon
                                  ) || 0}
                                </td>
                                <td colSpan={2}></td>
                               </tr>
                               </>
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      {/* Destination Details Section */}
                      <tr className="bg-white">
                        <td colSpan={11} className="border-b-gray-400">
                          <table className="rounded-t-md shadow-md">
                            <thead className="rounded-t-md">
                              <tr>
                                <th
                                  colSpan={9}
                                  className="p-3 bg-primary-tint text-sm border-b-gray-400"
                                >
                                  Destination Details
                                </th>
                              </tr>
                              <tr className="bg-primary-tint text-sm">
                                {[
                                  "Payout ID",
                                  "Payment Type",
                                  "Payment Date",
                                  "Mode",
                                  "UTR",
                                  "Description",
                                  "Amount",
                                  "Status",
                                ].map((header) => (
                                  <th
                                    key={header}
                                    className="p-3 border border-gray-300"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {trade.tradePmts &&
                              trade.tradePmts.filter(
                                (payment: any) =>
                                  payment.creditDebit === "INWARD"
                              ).length > 0 ? (
                                trade.tradePmts
                                  .filter(
                                    (payment: any) =>
                                      payment.creditDebit === "INWARD"
                                  )
                                  .map((detail: any, index: number) => (
                                    <tr
                                      key={index}
                                      className="text-sm bg-white"
                                    >
                                      <td className="p-3 border border-gray-300">
                                        {detail.payoutId}
                                      </td>
                                      <td className="p-3 border border-gray-300">
                                        {detail.pmtType || "-"}
                                      </td>
                                      <td className="p-3 border border-gray-300">
                                        {detail.pmtDate
                                          ? moment(detail.pmtDate).format(
                                              "DD-MMM-YYYY"
                                            )
                                          : "-"}
                                      </td>
                                      <td className="p-3 border border-gray-300">
                                        {detail.pmtMode || "-"}
                                      </td>
                                      <td className="p-3 border border-gray-300">
                                        {detail.utr || "-"}
                                      </td>
                                      <td className="p-3 border border-gray-300">
                                        {detail.desc || "-"}
                                      </td>
                                      <td className="p-3 border border-gray-300 color-primary border-b-gray-400">
                                        {getCurrencyNumberWithOutSymbol(
                                          detail.amount
                                        ) || 0}
                                      </td>
                                      <td className="p-3 border border-gray-300">
                                        {detail.status}
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr className="bg-white">
                                  <td
                                    colSpan={9}
                                    className="p-3 text-center border border-gray-300"
                                  >
                                    No Destination Details Available
                                  </td>
                                </tr>
                              )}
                             
                             {trade?.tradePmts?.filter(
                              (payment: any) =>
                                payment.creditDebit === "INWARD"
                            ).length > 0 && (
                              <>
                               <tr>
                               <td
                                  colSpan={6}
                                  className="p-3 border border-gray-300 text-right text-sm font-semibold"
                                >
                                  Total Amount
                                </td>
                                <td className="p-3 border border-gray-300 color-primary text-sm font-semibold">
                                  {getCurrencyNumberWithOutSymbol(
                                    calculateDestTotal(trade)
                                  )}
                                </td>
                                <td colSpan={2}></td>
                               </tr>
                                

                               <tr>
                                <td
                                  colSpan={6}
                                  className="p-3 border border-gray-300 text-right text-sm font-semibold"
                                >
                                  Final Destination Receivable
                                </td>
                                <td className="p-3 border border-gray-300 color-primary text-sm font-semibold">
                                {getCurrencyNumberWithOutSymbol(
                                    trade.netReceivable
                                  ) || 0}
                                </td>
                                <td colSpan={2}></td>
                                </tr>


                                <tr>
                                <td
                                  colSpan={6}
                                  className="p-3 border border-gray-300 text-right text-sm font-semibold"
                                >
                                   Reconciliation Amount
                                </td>
                                <td className="p-3 border border-gray-300 color-primary text-sm font-semibold">
                                {getCurrencyNumberWithOutSymbol(
                                    trade.destRecon
                                  ) || 0}
                                </td>
                                <td colSpan={2}></td>
                               </tr>
                               </>
                              )}
                              
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="md:text-center p-0">
                  <div className="bg-white">
                    <img
                      src={NodataAvailable}
                      alt="No Data"
                      className="pl-12 md:pl-0 md:mx-auto py-5"
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default ExcessPaymentReport;

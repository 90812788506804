import { FC, ReactNode, useEffect } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa6";
import PageHeader from "../components/commons/page-header";
import { BiMenu } from "react-icons/bi";
import mandilogo from "../assets/images/mandi-logo.png";
import { useState } from "react";
import { Button } from "../components/ui";
import logout from "../assets/images/logout.svg";
import { useDispatch, useSelector } from "react-redux";
import { handelLogout } from "../reducers/authSlice";
import { RiGroupFill } from "react-icons/ri";
import trades from "../assets/images/traderss.png";
import mandi from "../assets/images/loans_icon.svg";
import { updateActiveTradeTab } from "../reducers/tradeSlice";
import { TRootState } from "../store";
import close from "../assets/images/close.svg";
import reports from "../assets/images/reports.svg";
const PageLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginType } = useSelector((state: TRootState) => ({
    //  activeTradeTab: state.trades.activeTradeTab,
    loginType: state.auth.userInfo?.type,
  }));
  const [activeTab, setActiveTab] = useState("customers");
  const location = useLocation();
  const onClickTab = (tab: any) => {
    setActiveTab(tab);
  };
  const logOut = () => {
    localStorage.clear();
    dispatch(handelLogout(null));
    navigate("/");
  };
  const titles: Record<string, string> = {
    trades: "Trades",
    MyProfile: "My Profile",
    customers: "Customers",
    DailyPrice: "Daily Price",
    Market: "Market",
    Reports: "Reports",
  };
  const [title, setTitle] = useState(titles[activeTab] || "Default Title");
  // Assume activeTab is a state or prop that determines the current tab
  // var currentTitle = titles[activeTab] || "Default Title";
  // var currentTitle = "Default Title";
  // useEffect(() => {
  //   setTitle(titles[activeTab]);
  // }, [activeTab]);
  useEffect(() => {
    if (location.pathname === "/trades") {
      setActiveTab("trades");
      setTitle("Trades");
    } else if (location.pathname === "/my-profile") {
      setActiveTab("MyProfile");
      setTitle("My Profile");
    } else if (
      matchPath("/trade-detail/:traderId/:tradeId", location.pathname)
    ) {
      setActiveTab("trades");
      setTitle("Trades");
    } else if (matchPath("/supply-details/:postId", location.pathname)) {
      setActiveTab("trades");
      let id = location.pathname.split("/")[2];
      setTitle(`Trades | Supply Post ID: ${id}`);
    } else if (matchPath("/demand-details/:postId", location.pathname)) {
      console.log(location.pathname, "location.pathname");
      setActiveTab("trades");
      let id = location.pathname.split("/")[2];
      setTitle(`Trades | Demand Post ID: ${id}`);
    } else if (location.pathname == "/loans") {
      setActiveTab("loans");
      setTitle("Loans");
    } else if (
      matchPath("/application-view/:applicationId", location.pathname)
    ) {
      console.log(location.pathname, "location.pathname");
      setActiveTab("loans");
      let id = location.pathname.split("/")[2];
      setTitle(`Loans | Application ID: ${id}`);
    } else if (location.pathname === "/reports/debit-credit-reports") {
      setActiveTab("Reports");
      setTitle("Reports");
    } else if (location.pathname === "/reports/payment-receipts") {
      setActiveTab("Reports");
      setTitle("Reports");
    } else if (location.pathname === '/reports/allTradeDetail-Reports') {
      setActiveTab("Reports");
      setTitle("Reports");
    } else if(location.pathname === '/reports/ExcessPayment-Reports') {
      setActiveTab("Reports");
      setTitle("Reports");
    }
    else {
      setActiveTab("customers");
      setTitle("Customers");
    }
  }, [location.pathname]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Or use any breakpoint you prefer
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex">
      <div>
        <div
          className={`h-screen overflow-auto bg-white shadow-md font-semibold flex-shrink-0 relative transform transition-all duration-500 ease-in-out ${
            isOpen
              ? "translate-x-0 fixed top-0 left-0 w-[220px] z-[9999]"
              : "-translate-x-full w-0"
          } md:translate-x-0 md:w-[220px] md:static`}
          style={{
            borderRight: "1px solid #e4e4e4",
            position: isMobile ? "absolute" : "static",
          }}
        >
          <div className="shadow-md gap-4 flex items-center px-4">
            {isOpen ? (
              <img
                src={close}
                onClick={() => {
                  toggleSidebar();
                }}
              />
            ) : (
              <BiMenu
                size={25}
                style={{ color: "green" }}
                onClick={isMobile ? toggleSidebar : undefined}
              />
            )}
            <img
              src={mandilogo}
              className="w-24 ml-2"
              style={{ height: "60px", width: "50px" }}
              alt="Mandi Logo"
            />
          </div>
          <ul className="pl-4 py-4 ">
            {/* customer starts here */}
            <li
              className={`flex items-center gap-3 text-base font-normal p-3 cursor-pointer mb-4  ${
                activeTab == "customers" ? "bg-primary" : ""
              }`}
              style={{ borderRadius: "30px 0 0 30px" }}
              onClick={() => {
                navigate("/customers");
                onClickTab("customers");
                closeSidebar();
              }}
            >
              <div
                className={`w-7 h-7 br-100 flex justify-center items-center ${
                  activeTab == "customers" ? "bg-yellow" : "bg-gray-dark"
                }`}
              >
                <RiGroupFill className="w-4 h-4 color-primary" />
              </div>
              <p
                className={`text-sm font-semibold ${
                  activeTab == "customers" ? "color-yellow" : ""
                }`}
              >
                Customers
              </p>
            </li>

            {/* trade starts here */}
            <li
              className={`flex items-center gap-3 text-base font-normal p-3 cursor-pointer mb-4  ${
                activeTab == "trades" ? "bg-primary" : ""
              }`}
              style={{ borderRadius: "30px 0 0 30px" }}
              onClick={() => {
                navigate("/trades");
                onClickTab("trades");
                closeSidebar();
              }}
            >
              <div
                className={`w-7 h-7 br-100 flex justify-center items-center ${
                  activeTab == "trades" ? "bg-yellow" : "bg-gray-dark"
                }`}
              >
                {/* <AiFillHome className="w-4 h-4 color-primary" /> */}
                <img
                  src={trades}
                  alt="trades"
                  className="w-8 h-8 color-primary"
                />
              </div>
              <p
                className={`text-sm font-semibold ${
                  activeTab == "trades" ? "color-yellow" : ""
                }`}
              >
                Trades
              </p>
            </li>
            {loginType != "BAYER" && (
              <li
                className={`flex items-center gap-3 text-base font-normal p-3 cursor-pointer mb-4  ${
                  activeTab == "loans" ? "bg-primary" : ""
                }`}
                style={{ borderRadius: "30px 0 0 30px" }}
                onClick={() => {
                  navigate("/loans");
                  onClickTab("loans");
                  closeSidebar();
                }}
              >
                <div
                  className={`w-7 h-7 br-100 flex justify-center items-center ${
                    activeTab == "loans" ? "bg-yellow" : "bg-gray-dark"
                  }`}
                >
                  <img
                    src={mandi}
                    alt="mandi"
                    className="w-4 h-4 color-primary"
                  />
                </div>
                <p
                  className={`text-sm font-semibold ${
                    activeTab == "loans" ? "color-yellow" : ""
                  }`}
                >
                  {" "}
                  Loans
                </p>
              </li>
            )}
            {/* my-profile starts here */}
            <li
              className={`flex items-center gap-3 text-base font-normal p-3 cursor-pointer mb-4  ${
                activeTab == "Reports" ? "bg-primary" : ""
              }`}
              style={{ borderRadius: "30px 0 0 30px" }}
              onClick={() => {
                navigate("/reports/debit-credit-reports");
                onClickTab("Reports");
                closeSidebar();
              }}
            >
              <div
                className={`w-7 h-7 br-100 flex justify-center items-center ${
                  activeTab == "Reports" ? "bg-yellow" : "bg-gray-dark"
                }`}
              >
                <img
                  src={reports}
                  alt="mandi"
                  className="w-4 h-4 color-primary"
                />
              </div>
              <p
                className={`text-sm font-semibold ${
                  activeTab == "Reports" ? "color-yellow" : ""
                }`}
              >
                {" "}
                Reports
              </p>
            </li>
            <li
              className={`flex items-center gap-3 text-base font-normal p-3 cursor-pointer mb-4  ${
                activeTab == "MyProfile" ? "bg-primary" : ""
              }`}
              style={{ borderRadius: "30px 0 0 30px" }}
              onClick={() => {
                navigate("/my-profile");
                onClickTab("MyProfile");
                closeSidebar();
              }}
            >
              <div
                className={`w-7 h-7 br-100 flex justify-center items-center ${
                  activeTab == "MyProfile" ? "bg-yellow" : "bg-gray-dark"
                }`}
              >
                <FaUser className="w-4 h-4 color-primary" />
              </div>
              <p
                className={`text-sm font-semibold ${
                  activeTab == "MyProfile" ? "color-yellow" : ""
                }`}
              >
                {" "}
                My Profile
              </p>
            </li>

            {/* daily price starts */}
            {/* <li
            className={`flex items-center gap-3 text-base font-normal p-3 cursor-pointer mb-4  ${
              activeTab == "DailyPrice" ? "bg-primary" : ""
                      }`}
            style={{ borderRadius: "30px 0 0 30px" }}
            onClick={() => {
              navigate("/DailyPrice");
              onClickTab("DailyPrice");
            }}
          >
            <div
              className={`w-7 h-7 br-100 flex justify-center items-center ${
                activeTab == "DailyPrice" ? "bg-yellow" : "bg-gray-dark"
              }`}
            >
              <FaRupeeSign className="w-4 h-4 color-primary" />
            </div>
            <p
              className={`text-sm font-semibold ${
                activeTab == "DailyPrice" ? "color-yellow" : "" 
              }`}
            >
              {" "}
             Daily Price
            </p>
          </li> */}

            {/* market starts here */}
            {/* <li
            className={`flex items-center gap-3 text-base font-normal p-3 cursor-pointer mb-4  ${
              activeTab == "Market" ? "bg-primary" : ""
                      }`}
            style={{ borderRadius: "30px 0 0 30px" }}
            onClick={() => {
              navigate("/Market");
              onClickTab("Market");
            }}
          >
            <div
              className={`w-7 h-7 br-100 flex justify-center items-center ${
                activeTab == "Market" ? "bg-yellow" : "bg-gray-dark"
              }`}
            >
              <img src={market} className="w-7 h-7 color-primary" />
            </div>
            <p
              className={`text-sm font-semibold ${
                activeTab == "Market" ? "color-yellow" : "" 
              }`}
            >
              {" "}
             Market
            </p>
          </li> */}
            {/* logout button starts here */}
            <li>
              <div className="fixed bottom-20 md:bottom-10 left-18">
                <Button
                  className="mx-auto"
                  shape="round"
                  variant="red"
                  onClick={() => {
                    logOut();
                    dispatch(updateActiveTradeTab(""));
                  }}
                >
                  <img src={logout} className="pr-2" />
                  Logout
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="ml-auto grow h-screen overflow-auto relative ">
        <div className="flex w-full relative shadow-md md:shadow-none">
          <header className="flex items-center md:hidden  px-2 bg-white sticky top-0 z-[999]">
            {isOpen ? (
              ""
            ) : (
              <BiMenu
                size={25}
                style={{ color: "green" }}
                onClick={() => {
                  toggleSidebar();
                }}
                // className="block md:hidden p-2 text-gray-700"
              />
            )}
          </header>
          <PageHeader
            title={title}
            showTitle={true}
            showUserInfo={true}
            // showBackButton={true}
          />
        </div>

        <div
          className="overflow-auto"
          style={{
            height: "calc(100vh - 3.8rem)",
            backgroundColor: "#EFF3EF",
          }}
        >
          {children}
        </div>
      </div>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 z-[99] md:hidden"
          onClick={closeSidebar}
        ></div>
      )}
    </div>
  );
};

export default PageLayout;

import moment from "moment";
import { getCurrencyNumberWithOutSymbol } from "../../helpers/get-currency-number";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
import { FaSearch } from "react-icons/fa";
import { Input } from "../../components/ui";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import excel from "../../assets/images/excel.svg";
import useAllTradeDetailsReport from "./useAllTradeDetails";
import Loading from "../../assets/images/loading.gif";

const AllTradeDetailReports = () => {
  const { allTradeDetailReports, isLoading } = useAllTradeDetailsReport();

  const [filteredTraders, setFilteredTraders] = useState<any[]>([]);

  const [searchText, setSearchText] = useState("");
  const filterTraders = () => {
    let filtered = allTradeDetailReports;
    const trimmedSearchText = searchText.trim().toLowerCase();
    filtered = filtered?.filter(
      (option: any) =>
        option.tradeId &&
        option.tradeId.toString().toLowerCase().includes(trimmedSearchText)
    );
    setFilteredTraders(filtered);
  };

  const data = filteredTraders.map((item, i) => ({
    tradeId: item.tradeId,
    dispatchDate: item.dispatchDate,
    arlDate: item.arlDate,
    tradeType: item.tradeType,
    freightPaid: item.freightPaid,
    comm: item.comm,
    lbrCharges: item.lbrCharges,
    misc: item.misc,
    srcDisc: item.srcDisc,
    destDisc: item.destDisc,
    finalInvPtAmt: item.finalInvPtAmt,
    cropName: item.cropName,
    qtyMajor: item.qtyMajor,
    majorUnit: item.majorUnit,
    qtyMinor: item.qtyMinor,
    minorUnit: item.minorUnit,
    srcTradeName: item.srcTradeName,
    srcAddrLine: item.srcAddrLine,
    srcMarketName: item.srcMarketName,
    srcPf: item.srcPf,
    srcPfDisc: item.srcPfDisc,
    netPayable: item.netPayable,
    destTradeName: item.destTradeName,
    destAddrLine: item.destAddrLine,
    destMarketName: item.destMarketName,
    destPf: item.destPf,
    destPfDisc: item.destPfDisc,
    netReceivable: item.netReceivable,
  }));
  const handleExport = () => {
    const headers = [
      "Trade Id",
      "Dispatch Date",
      "Arrival Date",
      "Trade Type",
      "Freight Paid",
      "Commission",
      "Labour Charges",
      "Miscellaneous",
      "Source Discount",
      "Destination Discount",
      "Final Invoice",
      "Dest Due Date",
      "Crop Name",
      "Quantity Major",
      "Major Unit",
      "Quantity Minor",
      "Minor Unit",
      "Source Trade Name",
      "Source Address",
      "Source Market",
      "Source PF",
      "Source PF Discount",
      "Final Payable",
      "Source Trade Name",
      "Source Address",
      "Source Market",
      "Source PF",
      "Source PF Discount",
      "Final Payable",
    ];
    const exportData = [
      headers,
      ...data.map((row) => [
        row.tradeId,
        row.dispatchDate,
        row.arlDate,
        row.tradeType,
        row.freightPaid,
        row.comm,
        row.lbrCharges,
        row.misc,
        row.srcDisc,
        row.destDisc,
        row.finalInvPtAmt,
        row.cropName,
        row.qtyMajor,
        row.majorUnit,
        row.qtyMinor,
        row.minorUnit,
        row.srcTradeName,
        row.srcAddrLine,
        row.srcMarketName,
        row.srcPf,
        row.srcPfDisc,
        row.netPayable,
        row.destTradeName,
        row.destAddrLine,
        row.destMarketName,
        row.destPf,
        row.destPfDisc,
        row.netReceivable,
      ]),
    ];
    if (data.length > 0) {
      const worksheet = XLSX.utils.aoa_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");
      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, `Open Trade Summary report.xlsx`);
    } else {
      toast.error("No data available to export excel.");
    }
  };

  // Call filterTraders whenever searchText or selectedStatus in Redux changes
  useEffect(() => {
    filterTraders();
  }, [searchText, allTradeDetailReports]);

  const tableStyle: React.CSSProperties = {
    width: "100%",
    borderCollapse: "collapse",
    borderRadius: "10px",
    overflow: "hidden",
    border: "1px solid #ccc",
  };

  return (
    <>
      <div className="flex justify-between pb-3">
        <div
          className="relative border border-gray-300 rounded focus-within:border-green-500 focus-within:shadow-lg transition-all duration-300"
          style={{
            width: "30%",
          }}
        >
          <FaSearch
            className="absolute color-primary"
            size={15}
            style={{ top: "14px", left: "6px" }}
          />
          <Input
            type="text"
            palceholder="Search by Trade Id"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            inputStyle={{ textIndent: "22px", padding: "8px 5px" }}
          />
        </div>
        <div
          onClick={() => {
            handleExport();
          }}
          className="text-sm font-medium cursor-pointer flex items-center justify-center br-10 gap-2 bg-white"
          style={{
            padding: "8px 5px",
          }}
        >
          <img src={excel} alt="img" className="" style={{ width: "25px" }} />{" "}
          <span>Export to Excel</span>
        </div>
      </div>
      <div
            className="overflow-y-auto overflow-x-auto"
            style={{ height: "calc(100vh - 12rem)" }}
          >
        <table className="w-full border border-gray-300" style={{ borderRadius: "10px"}}>
          <thead className=" sticky top-0 bg-light-primary z-10">
            <tr className="bg-light-primary br-10 text-base">
              <th className="px-2 md:px-4 py-2 text-center border-r-gray-400">
                #
              </th>
              <tr className="text-base">
                <th
                  colSpan={11}
                  className="px-4 py-4 bg-light-primary border-r-gray-400 font-bold md:px-4 border-b-gray-400"
                >
                  Trade Details
                </th>
                <th
                  colSpan={5}
                  className="px-4 md:px-4 py-4 bg-light-primary border-r-gray-400 font-bold border-b-gray-400"
                >
                  Crop Details
                </th>
                <th
                  colSpan={6}
                  className="px-4 md:px-4 py-4 bg-light-primary font-bold border-r-gray-400 border-b-gray-400"
                >
                  Source Details
                </th>
                <th
                  colSpan={6}
                  className="px-4 md:px-4 py-4 bg-light-primary font-bold border-b-gray-400"
                >
                  Destination Details
                </th>
              </tr>
              <tr className="text-base">
                <th className="px-2 md:px-4 py-2" style={{ minWidth: "100px" }}>
                  Trade Id
                </th>
                <th
                  className="px-2 md:px-4 py-2 "
                  style={{ minWidth: "150px" }}
                >
                  Dispatch Date
                </th>
                <th className="px-2 md:px-4 py-2" style={{ minWidth: "150px" }}>
                  Arrival Date
                </th>
                <th
                  className="px-2 md:px-4 py-2"
                  style={{ minWidth: "150px", width: "150px" }}
                >
                  Trade Type
                </th>
                <th className="px-2 md:px-4 py-2" style={{ minWidth: "150px" }}>
                  Freight Paid (₹)
                </th>
                <th
                  className="px-2 md:px-4 py-2"
                  style={{ minWidth: "140px", width: "140px" }}
                >
                  Commission (₹)
                </th>
                <th className="px-2 md:px-4 py-2" style={{ minWidth: "150px" }}>
                  Labour Charges (₹)
                </th>
                <th className="px-2 md:px-4 py-2" style={{ minWidth: "180px" }}>
                  Miscellaneous (₹)
                </th>

                <th className="px-2 md:px-4 py-2" style={{ minWidth: "150px" }}>
                  Source Discount (₹)
                </th>
                <th className="px-2 md:px-4 py-2" style={{ minWidth: "150px" }}>
                  Destination Discount (₹)
                </th>
                <th
                  className="px-2 md:px-4 py-2 border-r-gray-400"
                  style={{
                    minWidth: "150px",
                  }}
                >
                  Final Invoice/ Patti Amount (₹)
                </th>
                <th
                  className="px-2 md:px-4 py-2"
                  style={{
                    minWidth: "200px",
                  }}
                >
                  Crop
                </th>
                <th
                  className="px-2 md:px-4 py-2"
                  style={{
                    minWidth: "150px",
                  }}
                >
                  Qty Major
                </th>
                <th
                  className="px-2 md:px-4 py-2"
                  style={{ minWidth: "160px", width: "160px" }}
                >
                  Major Unit
                </th>

                <th
                  className="px-2 md:px-4 py-2"
                  style={{
                    minWidth: "180px",
                    width: "180px",
                  }}
                >
                  Qty Minor
                </th>
                <th
                  className="px-2 md:px-4 py-2 border-r-gray-400"
                  style={{ minWidth: "170px" }}
                >
                  Minor Unit
                </th>

                <th
                  className="px-2 md:px-4 py-2"
                  style={{ minWidth: "170px", width: "170px" }}
                >
                  Trade Name
                </th>
                <th
                  className="px-2 md:px-4 py-2"
                  style={{ minWidth: "170px", width: "170px" }}
                >
                  Address
                </th>
                <th
                  className="px-2 md:px-4 py-2"
                  style={{ minWidth: "170px", width: "170px" }}
                >
                  Market
                </th>
                <th className="px-2 md:px-4 py-2" style={{ minWidth: "170px" }}>
                  Pf (₹)
                </th>
                <th className="px-2 md:px-4 py-2" style={{ minWidth: "170px" }}>
                  Pf Discount (₹)
                </th>
                <th
                  className="px-2 md:px-4 py-2 border-r-gray-400"
                  style={{ minWidth: "170px" }}
                >
                  Final Payable (₹)
                </th>

                <th
                  className="px-2 md:px-4 py-2"
                  style={{ minWidth: "170px", width: "170px" }}
                >
                  Trade Name
                </th>
                <th
                  className="px-2 md:px-4 py-2"
                  style={{ minWidth: "170px", width: "170px" }}
                >
                  Address
                </th>
                <th
                  className="px-2 md:px-4 py-2"
                  style={{ minWidth: "170px", width: "170px" }}
                >
                  Market
                </th>
                <th className="px-2 md:px-4 py-2" style={{ minWidth: "170px" }}>
                  Pf (₹)
                </th>
                <th className="px-2 md:px-4 py-2" style={{ minWidth: "170px" }}>
                  Pf Discount (₹)
                </th>
                <th className="px-2 md:px-4 py-2" style={{ minWidth: "170px" }}>
                  Final Receivable (₹)
                </th>
              </tr>
            </tr>
          </thead>

          {isLoading ? (
            <tbody>
              <tr>
                <td colSpan={100} className=" p-0">
                  <div
                    className="bg-white flex justify-between py-5"
                    style={{ paddingLeft: "400px" }}
                  >
                    <img src={Loading} alt="loading" className="w-16 h-16" />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : filteredTraders.length > 0 ? (
            <tbody>
              {(searchText !== ""
                ? filteredTraders.filter((trade) => {
                    return trade.tradeId.toString().includes(searchText);
                  })
                : filteredTraders
              ).map((trade, index) => (
                <tr key={trade.payoutId} className="bg-white text-sm">
                  <td className="px-2 md:px-4 py-2 text-center border-r-gray-400">
                    {index + 1}
                  </td>
                  <tr className="text-sm">
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{
                        minWidth: "100px",
                      }}
                    >
                      {trade.tradeId}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{
                        minWidth: "150px",
                      }}
                    >
                      {trade.dispatchDate
                        ? moment(trade.dispatchDate).format("DD-MMM-YYYY")
                        : "-"}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{
                        minWidth: "150px",
                      }}
                    >
                      {trade.arlDate
                        ? moment(trade.arlDate).format("DD-MMM-YYYY")
                        : "-"}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {trade.tradeType === "ORDER" ? "Order" : "Commission"}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.freightPaid) || 0}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "140px", width: "140px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.comm) || 0}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.lbrCharges) || 0}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "180px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.misc) || 0}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.srcDisc) || 0}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.destDisc) || 0}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2 border-r-gray-400"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.finalInvPtAmt) || 0}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "200px" }}
                    >
                      {" "}
                      {trade.cropName || "-"}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.qtyMajor) || 0}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "160px" }}
                    >
                      {" "}
                      {trade.majorUnit || "-"}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "180px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.qtyMinor) || 0}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2 border-r-gray-400"
                      style={{ minWidth: "170px" }}
                    >
                      {" "}
                      {trade.minorUnit || "-"}{" "}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "170px", width: "170px" }}
                    >
                      {" "}
                      {trade.srcTradeName || "-"}{" "}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "170px", width: "170px" }}
                    >
                        {[trade.srcCity, trade.srcState, trade.srcPincode].filter(Boolean).join(", ") || "-"}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "170px", width: "170px" }}
                    >
                      {" "}
                      {trade.srcMarketName || "-"}{" "}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "170px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.srcPf) || 0}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "170px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.srcPfDisc) || 0}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2 border-r-gray-400"
                      style={{ minWidth: "170px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.netPayable) || 0}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "170px", width: "170px" }}
                    >
                      {" "}
                      {trade.destTradeName || "-"}{" "}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "170px", width: "170px" }}
                    >
                      {" "}
                      {trade.destCity || trade.destState || trade.destPincode
                        ? `${trade.destCity}, ${trade.destState}, ${trade.destPincode}`
                        : "-"}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "170px", width: "170px" }}
                    >
                      {" "}
                      {trade.destMarketName || "-"}{" "}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "170px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.destPf) || 0}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "170px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.destPfDisc) || 0}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2"
                      style={{ minWidth: "170px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.netReceivable) || 0}
                    </td>
                  </tr>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={100} className="md:text-center p-0">
                  <div className="bg-white">
                    <img
                      src={NodataAvailable}
                      alt="No Data"
                      className="pl-12 md:pl-0 md:mx-auto py-5"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};
export default AllTradeDetailReports;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  TAddPayoutDetails,
  TCashReportsData,
  TCrop,
  TDestinationPartner,
  TPaymentHistory,
  TPayoutDetails,
  TProgressStatus,
  TProgressStatusObj,
  TProposals,
  TRecordPayoutData,
  TSupplyDemandCropImages,
  TSupplyDemandReq,
  TTradeCompleteDetai,
  TTrader,
  TTraderId,
  TTradeUpdateDetais,
} from "../types";

type TinitialState = {
  tradeDetails: TTradeCompleteDetai | null;
  tradeEditStatus: boolean;
  tradeId: number | null;
  traderId: number;
  traderDetails: TTraderId | null;
  tradeStatus: string;
  partyData: TDestinationPartner[];
  selectedParty: TDestinationPartner | null;
  selectedSrcParty: TDestinationPartner | null;
  selectedDate: string;
  selectedCrop: TCrop | null;
  deletedCrop: TCrop | null;
  tradeUpdateDetails: TTradeUpdateDetais | null;
  selectedTime: string;
  progressStatus: TProgressStatusObj | null;
  paymentUpdateStatus: boolean;
  paymentHistoryObj: TPaymentHistory | null;
  editAdvancePayment: boolean;
  selectedStatus: string[];
  allTraders: TTrader[];
  activeTradeTab: string;
  supplyDetailsById: TSupplyDemandReq | null;
  proposalCropImages: TSupplyDemandCropImages[];
  demandDetailsById: TSupplyDemandReq | null;
  traderContactInfo: TProposals | null;
  allSupplys: TSupplyDemandReq[];
  allDemands: TSupplyDemandReq[];
  addPayoutDetails: TAddPayoutDetails;
  payoutDetails: TPayoutDetails[];
  selectedPayout: TPayoutDetails;
  activeTradeTypeTab: string;
  activeTradePayTypeTab: string;
  notificationTraderId: number;
  refresh: boolean;
  notificationTradeId: number;
  isPaymentGateway: boolean;
  payoutCurrentStep: number;
  recordPayoutData: TRecordPayoutData;
  reportsPayouts: TCashReportsData[];
  selectedReportStatus: string[];
  selectedReportStatusFilter: string[];
  traderType: string
};
const initialState: TinitialState = {
  tradeDetails: null,
  tradeEditStatus: false,
  tradeId: 0,
  traderId: 0,
  tradeStatus: "",
  traderDetails: null,
  partyData: [],
  selectedParty: null,
  selectedDate: moment().format("YYYY-MM-DD"),
  selectedCrop: null,
  deletedCrop: null,
  tradeUpdateDetails: null,
  selectedTime: "",
  progressStatus: null,
  paymentUpdateStatus: false,
  paymentHistoryObj: null,
  selectedSrcParty: null,
  editAdvancePayment: false,
  selectedStatus: [],
  allTraders: [],
  activeTradeTab: "",
  supplyDetailsById: null,
  proposalCropImages: [],
  demandDetailsById: null,
  traderContactInfo: null,
  allSupplys: [],
  allDemands: [],
  addPayoutDetails: {
    traderId: 0,
    accountHolder: "",
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    status: "",
    accountType: "",
    traderType: "",
    contactNumber: "",
    emailAddress: "",
    note1: "",
    note2: "",
    isApiCall: false,
    type: "",
    refId: "",
  },
  payoutDetails: [],
  selectedPayout: {
    id: 0,
    traderId: 0,
    actHolder: "",
    actNum: "",
    ifsc: "",
    bank: "",
    rzpFa: "",
    rzpCont: "",
    mobile: "",
    status: 0,
    createdOn: "",
    traderType: "",
    contactNumber: "",
    emailAddress: "",
    note1: "",
    note2: "",
    actType: "",
    email: "",
    type: "type",
  },
  activeTradeTypeTab: "",
  activeTradePayTypeTab: "",
  notificationTraderId: 0,
  refresh: false,
  notificationTradeId: 0,
  isPaymentGateway: false,
  payoutCurrentStep: 1,
  recordPayoutData: {
    partyId: "",
    paymentDate: "",
    paidAmount: "",
    paymentType: "",
    paymentMode: "",
    comments: "",
    paymentTypeVal: "",
    requestAdv: 0,
    pmtReceiptDate: "",
    desc: "",
    utr: "",
    inOutWard: "",
    fundAccId: "",
    accountNumber: "",
    otpValidated: false,
    otp: "",
    refId: "",
    id: 0,
    availableBalance: 0,
  },
  reportsPayouts: [],
  selectedReportStatus: [],
  selectedReportStatusFilter: [],
  traderType: ''
};
const tradeSlice = createSlice({
  name: "trades",
  initialState: initialState,
  reducers: {
    updateTradeDetails: (
      state,
      action: PayloadAction<{
        tradeDetails: TTradeCompleteDetai | null;
      }>
    ) => {
      state.tradeDetails = action.payload.tradeDetails;
    },
    editTrade: (
      state,
      action: PayloadAction<{
        tradeEditStatus: boolean;
      }>
    ) => {
      state.tradeEditStatus = action.payload.tradeEditStatus;
    },
    updateTradeId: (
      state,
      action: PayloadAction<{
        tradeId: number | null;
      }>
    ) => {
      state.tradeId = action.payload.tradeId;
    },
    updateRefresh: (
      state,
      action: PayloadAction<{
        refresh: boolean;
      }>
    ) => {
      state.refresh = action.payload.refresh;
    },
    updateNotifiTraderId: (
      state,
      action: PayloadAction<{
        notificationTraderId: number;
      }>
    ) => {
      state.notificationTraderId = action.payload.notificationTraderId;
    },

    updateNotifiTradeId: (
      state,
      action: PayloadAction<{
        notificationTradeId: number;
      }>
    ) => {
      state.notificationTradeId = action.payload.notificationTradeId;
    },

    updateTraderId: (
      state,
      action: PayloadAction<{
        traderId: number;
        tradeStatus: "ALL" | "OPEN" | "CLOSED" | "LEDGER";
      }>
    ) => {
      state.traderId = action.payload.traderId;
      state.tradeStatus = action.payload.tradeStatus;
      //   if (action.payload.traderDetails != null) {
      //   state.traderDetails = action.payload.traderDetails;}
    },
    updatePartyData: (
      state,
      action: PayloadAction<{
        partyData: TDestinationPartner[];
      }>
    ) => {
      state.partyData = action.payload.partyData;
    },
    updateCropData: (
      state,
      action: PayloadAction<{
        selectedCrop: TCrop | null;
        selectedParty?: TDestinationPartner | null;
        deletedCrop?: TCrop | null;
        selectedSrcParty?: TDestinationPartner | null;
      }>
    ) => {
      // if (action.payload.selectedCrop) {

      // }

      if (action.payload.selectedParty != null) {
        state.selectedParty = action.payload.selectedParty;
      }
      if (action.payload.selectedSrcParty != null) {
        state.selectedSrcParty = action.payload.selectedSrcParty;
      }
      state.selectedCrop = action.payload.selectedCrop;
      if (action.payload.deletedCrop) {
        state.deletedCrop = action.payload.deletedCrop;
      }
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    arrivalTradeDetails: (
      state,
      action: PayloadAction<{
        tradeUpdateDetails: TTradeUpdateDetais | null;
      }>
    ) => {
      state.tradeUpdateDetails = action.payload.tradeUpdateDetails;
    },
    setSelectedTime: (state, action) => {
      state.selectedTime = action.payload;
    },
    updateProgressDetails: (
      state,
      action: PayloadAction<{
        progressStatus: TProgressStatusObj | null;
      }>
    ) => {
      state.progressStatus = action.payload.progressStatus;
    },
    updatePaymentUpdateStatus: (
      state,
      action: PayloadAction<{
        paymentUpdateStatus: boolean;
        isPaymentGateway?: boolean;
      }>
    ) => {
      state.paymentUpdateStatus = action.payload.paymentUpdateStatus;
      state.isPaymentGateway = action.payload.isPaymentGateway ?? false;
    },
    getPaymentHistory: (
      state,
      action: PayloadAction<{
        paymentHistoryObj: TPaymentHistory | null;
      }>
    ) => {
      state.paymentHistoryObj = action.payload.paymentHistoryObj;
    },
    updateEditAdvPayment: (
      state,
      action: PayloadAction<{
        editAdvancePayment: boolean;
      }>
    ) => {
      state.editAdvancePayment = action.payload.editAdvancePayment;
    },
    setSelectedStatus: (state, action: PayloadAction<string[]>) => {
      state.selectedStatus = action.payload;
    },
    setSelectedREportStatus: (state, action: PayloadAction<string[]>) => {
      state.selectedReportStatus = action.payload;
    },
    setSelectedREportStatusFilter: (state, action: PayloadAction<string[]>) => {
      state.selectedReportStatusFilter = action.payload;
    },
    updateTraders: (
      state,
      action: PayloadAction<{
        allTraders: TTrader[];
      }>
    ) => {
      state.allTraders = action.payload.allTraders;
    },
    updateActiveTradeTab: (state, action: PayloadAction<string>) => {
      state.activeTradeTab = action.payload;
    },
    updateSupplyDetails: (
      state,
      action: PayloadAction<{
        supplyDetailsById: TSupplyDemandReq | null;
      }>
    ) => {
      state.supplyDetailsById = action.payload.supplyDetailsById;
    },
    updateProposalCropImgs: (
      state,
      action: PayloadAction<{
        proposalCropImages: TSupplyDemandCropImages[];
      }>
    ) => {
      state.proposalCropImages = action.payload.proposalCropImages;
    },
    updateTraderContact: (
      state,
      action: PayloadAction<{
        traderContactInfo: TProposals | null;
      }>
    ) => {
      state.traderContactInfo = action.payload.traderContactInfo;
    },
    updateDemandDetails: (
      state,
      action: PayloadAction<{
        demandDetailsById: TSupplyDemandReq | null;
      }>
    ) => {
      state.demandDetailsById = action.payload.demandDetailsById;
    },
    updateAllSupplyDetails: (
      state,
      action: PayloadAction<{
        allSupplys: TSupplyDemandReq[];
      }>
    ) => {
      state.allSupplys = action.payload.allSupplys;
    },
    updateAllDemandsDetails: (
      state,
      action: PayloadAction<{
        allDemands: TSupplyDemandReq[];
      }>
    ) => {
      state.allDemands = action.payload.allDemands;
    },
    setPayoutDetails: (
      state,
      action: PayloadAction<{
        traderId: number;
        traderType: string;
        contactNumber: string;
        emailNumber: string;
        isApiCall: boolean;
        type: string;
      }>
    ) => {
      state.addPayoutDetails = {
        traderId: action.payload.traderId,
        accountHolder: "",
        accountNumber: "",
        bankName: "",
        ifscCode: "",
        status: "",
        accountType: "bank_account",
        traderType: action.payload.traderType,
        contactNumber: action.payload.contactNumber,
        emailAddress: action.payload.emailNumber,
        note1: "",
        note2: "",
        isApiCall: action.payload.isApiCall,
        type: action.payload.type,
        refId: "",
      };
    },
    updatePayoutDetails: (
      state,
      action: PayloadAction<{
        addPayoutDetails: TAddPayoutDetails;
      }>
    ) => {
      state.addPayoutDetails = action.payload.addPayoutDetails;
    },
    storePayoutDetails: (state, action: PayloadAction<TPayoutDetails[]>) => {
      state.payoutDetails = action.payload;
    },
    resetPayoutDetails: (state) => {
      state.payoutDetails = [];
    },
    setSelectedPayout: (state, action: PayloadAction<TPayoutDetails>) => {
      state.selectedPayout = action.payload;
    },
    UpdateTradeTypeActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTradeTypeTab = action.payload;
    },
    UpdateTradePaymentActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTradePayTypeTab = action.payload;
    },
    setPayoutCurrentStep: (state, action) => {
      state.payoutCurrentStep = action.payload;
    },
    setRecordPayoutData: (
      state,
      action: PayloadAction<{
        partyId: number;
        inOutWard: string;
        accountNumber: string;
        fundAccId: string;
      }>
    ) => {
      state.recordPayoutData = {
        partyId: action.payload.partyId,
        paymentDate: moment().format("YYYY-MM-DD"),
        paidAmount: "",
        paymentType: "",
        paymentMode: "",
        comments: "",
        paymentTypeVal: "",
        requestAdv: 0,
        pmtReceiptDate: "",
        desc: "",
        utr: "",
        inOutWard: action.payload.inOutWard,
        accountNumber: action.payload.accountNumber,
        fundAccId: action.payload.fundAccId,
        otpValidated: false,
        otp: "",
        refId: "",
        id: 0,
        availableBalance: 0,
      };
      state.payoutCurrentStep = 1;
    },
    updateRecordPayoutData: (state, action) => {
      state.recordPayoutData = action.payload;
    },
    updateReportsPayoutsData: (state, action) => {
      state.reportsPayouts = action.payload;
    },

    UpdateTraderType: (state, action: PayloadAction<string>) => {
      state.traderType = action.payload;
    },
  },
});

export const {
  updateTradeDetails,
  editTrade,
  updateTraderId,
  updateTradeId,
  updatePartyData,
  setSelectedDate,
  updateCropData,
  arrivalTradeDetails,
  setSelectedTime,
  updateProgressDetails,
  updatePaymentUpdateStatus,
  getPaymentHistory,
  updateEditAdvPayment,
  setSelectedStatus,
  updateTraders,
  updateActiveTradeTab,
  updateSupplyDetails,
  updateProposalCropImgs,
  updateDemandDetails,
  updateTraderContact,
  updateAllSupplyDetails,
  updateAllDemandsDetails,
  setPayoutDetails,
  updatePayoutDetails,
  storePayoutDetails,
  resetPayoutDetails,
  setSelectedPayout,
  UpdateTradeTypeActiveTab,
  UpdateTradePaymentActiveTab,
  updateNotifiTraderId,
  updateRefresh,
  updateNotifiTradeId,
  setPayoutCurrentStep,
  setRecordPayoutData,
  updateRecordPayoutData,
  updateReportsPayoutsData,
  setSelectedREportStatus,
  setSelectedREportStatusFilter,
  UpdateTraderType
} = tradeSlice.actions;
export default tradeSlice.reducer;

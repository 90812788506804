import { useEffect, useRef, useState } from "react";
import { PanelSlider } from "../../../../components/ui";
import BillsTable from "../Bills/BillsTable";
import PartnerInfo from "../../../../components/partner-info";
import cancelIcon from "../../../../assets/images/close.svg";
import Comments from "../Review-comments/Comments";
import moment from "moment";
import { getCurrencyNumberWithSymbol } from "../../../../helpers/get-currency-number";
import { TApplicationViewDetails } from "../../../../types";
import DocumentPreview from "../../../../components/commons/document-preview";
import EmptyProfile from "../../../../assets/images/profile.jpg";
import { useSelector } from "react-redux";
import { TRootState } from "../../../../store";
import { getMaskedMobileNumber } from "../../../../helpers/get-currency-number";
import { getLoanTypeName } from "../../../../helpers/getText";
const LoanDetails: React.FC<{
  details: TApplicationViewDetails;
  padding?: string;
}> = ({ details, padding }) => {
  const [openBills, setOpenBills] = useState(false);

  const { billsView } = useSelector((state: TRootState) => ({
    billsView: state.loans.billsView,
  }));

// Close PanelSlider on outside click
    const panelRef = useRef<HTMLDivElement | null>(null);
  
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
          setOpenBills(false);
        }
      };
      if (openBills) {
        document.addEventListener("mousedown", handleClickOutside);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [openBills]);

  return (
    <>
      <div className="padding">
        <div className="bg-light-primary px-4 py-2 mt-3 rounded-t-md">
          <span className="text-sm font-semibold">
            Beneficiary Loan Details
          </span>
        </div>
        <div className="bg-white px-3 py-4 border">
          <div
            className="grow-[2.5] basis-0 flex justify-between"
            style={{ flexBasis: "4rem", width: "80%" }}
          >
            <div
              className="grow-[2.5] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              {/* <span className="text-sm">Applied On</span>
              <span className="font-semibold text-sm">
                {moment(details?.disbDate).format("DD-MMM-YYYY") || "-"}
              </span> */}{" "}
              <span className="text-sm">Loan ID</span>
              <span className="text-sm color-blue font-semibold">
                {details?.disbId}{" "}
              </span>
              <span className="text-sm">
                {moment(details?.createdOn).format(
                  "DD-MMM-YY | hh:mm:A"
                ) || "-"}
              </span>
            </div>
            {
              details?.invAmt > 0 &&
              <div
                className="grow-[2.5] basis-0 flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Total Bill Amount</span>
                <span className="font-semibold text-sm">
                  {" "}
                  {getCurrencyNumberWithSymbol(details?.invAmt) || "-"}
                </span>
              </div>
            }
            <div
              className="grow-[2.5] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Cash Requested</span>
              <span className="font-semibold text-sm">
                {getCurrencyNumberWithSymbol(details?.tradeAdv) || "-"}
              </span>
            </div>
            <div
              className="grow-[2.5] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Loan Type</span>
              <span className="font-semibold text-sm">
                {getLoanTypeName(details?.loanType) || "-"}
              </span>
            </div>
          </div>
          <div className="border-b px-2 py-2"></div>
          {
            details?.bills?.length > 0
            && <div>
              <div className="flex py-2 px-2">
                <span className="text-sm">
                  Selected Bills ({details?.bills?.length})
                </span>
                <span
                  className="pl-2 color-blue cursor-pointer text-sm font-semibold"
                  onClick={() => {
                    setOpenBills(true);
                  }}
                >
                  See All
                </span>
              </div>
              <span className="font-semibold text-sm color-blue px-2">
                {details?.bills?.length > 0 &&
                  details?.bills?.map((bill, i) => (
                    <>
                      {bill.caBSeq + (i !== details?.bills?.length - 1 ? " ," : "")}
                    </>
                  ))}
              </span>
              <div className="border-b px-2 py-1"></div>
            </div>
          }
          <div className="flex pt-4">
            <div className="flex border-r " style={{ width: "60%" }}>
              <div
                className="grow-[2] basis-0 px-2 flex flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Principal Amount</span>
                <span className="font-semibold text-sm">
                  {" "}
                  {getCurrencyNumberWithSymbol(details?.tradeAdv) || "-"}
                </span>
              </div>
              <span
                className="grow-[2] basis-0 px-2 flex flex-col"
                style={{ width: "20%" }}
              >
                {" "}
                <span className="text-sm">Interest Rate</span>
                <span className="font-semibold text-sm">
                  {details?.interestRate ? details?.interestRate + "%" : "-"}
                </span>
              </span>
              <div
                className="grow-[2] basis-0 px-2 flex flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Tenure Period</span>
                <span className="font-semibold text-sm">
                  {details?.tenure ? details?.tenure + " Days" : "-"}
                </span>
              </div>
            </div>
            <div className="flex" style={{ width: "40%" }}>
              <span
                className="grow-[2] basis-0 px-2 flex flex-col"
                style={{ width: "20%" }}
              >
                {" "}
                <span className="text-sm">Repayment Amount</span>
                <span className="font-semibold text-sm">
                  {getCurrencyNumberWithSymbol(details?.repayAmt) || "-"}
                </span>
              </span>
              <div
                className="grow-[2] basis-0 flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Due Date</span>
                <span className="font-semibold text-sm">
                  {moment(details?.dueDate).format("DD-MMM-YYYY")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light-primary px-4 py-2">
          <span className="text-sm font-semibold">
            Beneficiary Bank Details
          </span>
        </div>

        <div className="border-b border text-sm py-4 px-2 cursor-pointer items-center bg-white">
          <div className="flex">
            <div
              className="grow-[2] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Benificiary Name</span>
              <span className="font-semibold text-sm">
                {details?.partyName || "-"}
              </span>
            </div>
            <div
              className="grow-[2] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Mobile Number</span>
              <span className="font-semibold text-sm">
                {details?.partyMobile || "-"}
              </span>
            </div>
            <div
              className="grow-[2] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Holder Name</span>
              <span className="font-semibold text-sm">
                {details?.payoutAcc?.actHolder || "-"}
              </span>
            </div>
            <div
              className="grow-[2] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Account Number</span>
              <span className="font-semibold text-sm">
                {details?.payoutAcc?.actNum
                  ? getMaskedMobileNumber(details?.payoutAcc?.actNum)
                  : "-"}
              </span>
            </div>
            <div
              className="grow-[2] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">IFSC Code</span>
              <span className="font-semibold text-sm">
                {details?.payoutAcc?.ifsc || "-"}
              </span>
            </div>
            <div
              className="grow-[2] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Bank Name</span>
              <span className="font-semibold text-sm">
                {details?.payoutAcc?.bank || "-"}
              </span>
            </div>
          </div>
          {/* <div className="border-b px-2 py-2 mb-2"></div> */}

          {/* <div className="flex py-2">
            <div
              className="grow-[2] basis-0 flex px-2 flex-col"
            >
              <span className="text-sm pb-1">Cancelled Cheque</span>
              <div className="flex overflow-auto"> */}
          {/* {details?.kycDocs &&
                  details.kycDocs.filter((doc) => doc.docType === "BANK_STATEMENT")
                    .length !== 0 ? (
                  details.kycDocs
                    .filter((doc) => doc.docType === "BANK_STATEMENT")
                    .map((image, i) => (
                      <div
                        key={i}
                        className="shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                        style={{
                          width: "33.333%",
                          height: "10rem",
                        }}
                      >
                        <a href={image.docUrl} target="_blank">
                          <DocumentPreview
                            url={image.docUrl}
                            docType={image.docUrl.includes(".pdf") ? "pdf" : "image"}
                          />
                        </a>
                      </div>
                    ))
                ) : (
                  <div
                    className="border rounded-sm border flex items-center justify-center p-4 text-sm text-center"
                    style={{
                      width: "40%",
                      height: "10rem",
                    }}
                  >
                    <span className="text-xs">Not Uploaded Cancelled Cheque</span>
                  </div>
                )} */}
          {/* <div
                    className="border rounded-sm flex items-center justify-center p-4 text-sm text-center"
                    style={{
                      width: "40%",
                      height: "10rem",
                    }}
                  >
                    <span className="text-xs">Not Uploaded Cancelled Cheque</span>
                  </div>
              </div>
            </div> */}

          {/* <div
              className="grow-[2] basis-0 flex px-2 flex-col"
            >
              <span className="text-sm pb-1">Bank Statement</span>
              <div className="flex overflow-auto">
                {details?.kycDocs &&
                  details.kycDocs.filter((doc) => doc.docType === "BANK_STATEMENT")
                    .length !== 0 ? (
                  details.kycDocs
                    .filter((doc) => doc.docType === "BANK_STATEMENT")
                    .map((image, i) => (
                      <div
                        key={i}
                        className="shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                        style={{
                          width: "33.333%",
                          height: "10rem",
                        }}
                      >
                        <a href={image.docUrl} target="_blank">
                          <DocumentPreview
                            url={image.docUrl}
                            docType={image.docUrl.includes(".pdf") ? "pdf" : "image"}
                          />
                        </a>
                      </div>
                    ))
                ) : (
                  <div
                    className="border rounded-sm border flex items-center justify-center p-4 text-sm text-center"
                    style={{
                      width: "40%",
                      height: "10rem",
                    }}
                  >
                    <span className="text-xs">Not Uploaded Bank_Statement</span>
                  </div>
                )}
              </div>
            </div> */}
          {/* </div>  */}
        </div>
        {(details?.disbStatus == "APPROVED" ||
          details?.disbStatus == "DISBURSED") && (
            <div>
              <Comments data={details} type="REVIEWED" />
            </div>
          )}
      </div>

      <div>
        <PanelSlider
          direction="right_to_left"
          open={openBills}
          onClose={() => {
            setOpenBills(false);
          }}
          style={{ width: "50vw" }}
        >
          <div 
          ref={panelRef}
          onMouseDown={(e) => e.stopPropagation()} 
          onClick={(e) => e.stopPropagation()}
           className="panel-content"
          >
          <div className="py-4 px-4 border-b flex items-center justify-between">
            <div className="text-base font-semibold">
              <h2>All Bills</h2>
            </div>
            <div>
              <img
                src={cancelIcon}
                className="cursor-pointer"
                onClick={() => {
                  setOpenBills(false);
                }}
              />
            </div>
          </div>
          {openBills && (
            <div className="p-4">
              <div className="border br-10 flex justify-center items-center">
                <div
                  className="grow-[2] basis-0 flex flex-col px-4 py-4"
                  style={{ flexBasis: "4rem", width: "100%" }}
                >
                  <PartnerInfo
                    profilePic={details?.profilePic || EmptyProfile}
                    name={details?.partyName || ""}
                    shortName={details?.shortCode || ""}
                    partnerType={details?.partyType + "ID"}
                    partnerId={details?.partyId}
                    partnerMobile={details?.partyMobile || ""}
                    partyName={details?.addressLine}
                    fromLoansParty={true}
                  />
                </div>

                <div
                  className="grow-[2] basis-0 flex px-2 flex-col"
                  style={{ width: "20%" }}
                >
                  <span className="text-sm">Market Name</span>
                  <span className="font-semibold text-sm">
                    {billsView?.marketName}
                  </span>
                </div>
              </div>
              <div className="py-4">
                <BillsTable billsData={details?.bills} />
              </div>
            </div>
          )}
          </div>
        </PanelSlider>
      </div>
    </>
  );
};
export default LoanDetails;

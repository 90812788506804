import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { TRootState } from "../../../store";
import { TBeneficiaryDetails, TBorrowerDetails } from "../../../types";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MultiValueGeneric } from "react-select/dist/declarations/src/components/MultiValue";
import Compressor from "compressorjs";
const useBorrowerDetailsEdit = ({
  cpId,
  appId,
  type,
  modalClose,
}: {
  cpId?: number;
  appId?: number;
  type: string;
  modalClose?: any;
}) => {
  const { handleGetCall, handlePutCall, handlePostCall } = useAxiosClient();
  const navigate = useNavigate();

  const [kycDocs, setKycDocs] = useState<
    Array<{ docType: "image" | "pdf"; url: string; type: string }>
  >([]);
  const { borrowerDetailsObj, adminId, beneficiaryDetailsObj } = useSelector(
    (state: TRootState) => ({
      borrowerDetailsObj: state.borrowers.borrowerDetails,
      adminId: state.auth.adminId,
      beneficiaryDetailsObj: state.borrowers.beneficiaryDetails,
    })
  );
 
  const [beneficiaryDetails, setBeneficiaryDetails] =
    useState<TBeneficiaryDetails | null>(beneficiaryDetailsObj);
  const [borrowerDetails, setBorrowerDetails] =
    useState<TBorrowerDetails | null>(borrowerDetailsObj);
  const [selectedDate, setSelectedDate] = useState(
    moment().add(1, "year").subtract(1, "day").format("YYYY-MM-DD")
  );
  const onChangeStatus = (status: string) => {
    if (type == "TRADER") {
      setBorrowerDetails({
        ...borrowerDetails!,
        status: status,
      });
    } else {
      setBeneficiaryDetails({
        ...beneficiaryDetails!,
        appStatus: status,
      });
    }
  };
  const handleDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    setSelectedDate(date);
    setBorrowerDetails({
      ...borrowerDetails!,
      renewDate: date,
    });
    setBeneficiaryDetails({
      ...beneficiaryDetails!,
      renewDate: date,
    });
  };
  const onUploadReceiptImage = async (
    type:
      | "AADHAR"
      | "PAN_CARD"
      | "BANK_STATEMENT"
      | "ITR_STATEMENT"
      | "ANALYSIS_REPORT"
      | "TRADE_LICENSE"
      | "BANK_PASSBOOK",
    files: FileList | File[] // Accept multiple files
  ) => {
    const uploadPromises = Array.from(files).map(async (file) => {
      let fileType = "";
      if (file.type === "application/pdf") {
        fileType = "pdf";
      } else if (file.type.includes("image/")) {
        fileType = "image";
      }

      let formdata = new FormData();
      formdata.append("name", type);
      formdata.append("category", "PERSONAL");
      formdata.append("tpId", `${adminId}`);

      if (fileType === "pdf") {
        formdata.append("file", file, file.name.replace(" ", "-"));
        let imageUploadResponse = await handlePostCall<string[]>({
          URL: "/mandi/files/kyc-doc/upload",
          apiParams: formdata,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        });
        let url = imageUploadResponse.data[0];
        setKycDocs((prevDocs) => [
          { docType: "pdf", url: url, type: type },
          ...prevDocs,
        ]);
      } else {
        console.log(file, "file");
        new Compressor(file, {
          quality: 0.8,
          success: async (compressedResult) => {
            formdata.append(
              "file",
              compressedResult,
              file.name.replace(" ", "-")
            );
            let imageUploadResponse = await handlePostCall<string[]>({
              URL: "/mandi/files/kyc-doc/upload",
              apiParams: formdata,
              config: {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              },
            });
            let url = imageUploadResponse.data[0];

            setKycDocs((prevDocs) => [
              { docType: "image", url: url, type: type },
              ...prevDocs,
            ]);
          },
        });
      }
    });

    await Promise.all(uploadPromises);
  };

  const onRemoveReceiptImage = (
    image: string,
    isExist: boolean = false,
    index: number = 0
  ) => {
    if (isExist === true && borrowerDetails?.kycDocs !== null) {
      let invoices = [...borrowerDetails?.kycDocs!];
      let i = invoices.findIndex((img) => img.id === index);
      invoices[i] = { ...invoices[i], status: 0 };
      console.log(invoices[i]);
      setBorrowerDetails({
        ...borrowerDetails!,
        kycDocs: invoices,
      });
    } else {
      let newtapalInvoice = [...kycDocs];
      let index = newtapalInvoice.findIndex((img) => img.url === image);
      newtapalInvoice.splice(index, 1);
      setKycDocs(newtapalInvoice);
    }
  };

  const onUploadBeneficiaryImage = async (
    type: "AADHAR" | "PAN_CARD" | "BANK_STATEMENT" | "BANK_PASSBOOK",
    files: FileList | File[] // Accept multiple files
  ) => {
    const uploadPromises = Array.from(files).map(async (file) => {
      let fileType = "";
      if (file.type === "application/pdf") {
        fileType = "pdf";
      } else if (file.type.includes("image/")) {
        fileType = "image";
      }

      let formdata = new FormData();
      formdata.append("name", type);
      formdata.append("category", "PERSONAL");
      formdata.append("tpId", `${adminId}`);

      if (fileType === "pdf") {
        formdata.append("file", file, file.name.replace(" ", "-"));
        let imageUploadResponse = await handlePostCall<string[]>({
          URL: "/mandi/files/kyc-doc/upload",
          apiParams: formdata,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        });
        let url = imageUploadResponse.data[0];
        setKycDocs((prevDocs) => [
          { docType: "pdf", url: url, type: type },
          ...prevDocs,
        ]);
      } else {
        console.log(file, "file");
        new Compressor(file, {
          quality: 0.8,
          success: async (compressedResult) => {
            formdata.append(
              "file",
              compressedResult,
              file.name.replace(" ", "-")
            );
            let imageUploadResponse = await handlePostCall<string[]>({
              URL: "/mandi/files/kyc-doc/upload",
              apiParams: formdata,
              config: {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              },
            });
            let url = imageUploadResponse.data[0];

            setKycDocs((prevDocs) => [
              { docType: "image", url: url, type: type },
              ...prevDocs,
            ]);
          },
        });
      }
    });

    await Promise.all(uploadPromises);
  };

  const onRemoveBeneficiaryImage = (
    image: string,
    isExist: boolean = false,
    index: number = 0
  ) => {
    if (isExist === true && beneficiaryDetails?.kycDocs !== null) {
      let invoices = [...beneficiaryDetails?.kycDocs!];
      let i = invoices.findIndex((img) => img.id === index);
      invoices[i] = { ...invoices[i], status: 0 };
      console.log(invoices[i]);
      setBeneficiaryDetails({
        ...beneficiaryDetails!,
        kycDocs: invoices,
      });
    } else {
      let newtapalInvoice = [...kycDocs];
      let index = newtapalInvoice.findIndex((img) => img.url === image);
      newtapalInvoice.splice(index, 1);
      setKycDocs(newtapalInvoice);
    }
  };

  const submit = async () => {
    if (!borrowerDetails?.onoScore) {
      toast.error("Please enter ONO risk score ");
      return;
    }
    if (!borrowerDetails?.eligibleLimit) {
      toast.error("Please enter Cash Limit");
      return;
    }

    if (borrowerDetails?.intrestRate == 0 && type === "TRADER") {
      toast.error("Please enter Interest Rate ");
      return;
    }
    console.log(borrowerDetails?.tenureString, "borrowerDetails?.tenureString");
    if (borrowerDetails?.tenureString == "") {
      toast.error("Please select Tenure Period ");
      return;
    }
    if (
      borrowerDetails?.eligibleLimit <= borrowerDetails?.utilisedLimit &&
      type === "TRADER"
    ) {
      toast.error("Cash limit always greater than utilised limit");
      return;
    }

    const images = [];
    for (let file of kycDocs) {
      images.push({
        appId: appId,
        docType: file.type,
        docUrl: file.url,
        id: 0,
        pfId: borrowerDetails?.pfId,
        platform: "CASH",
        status: 1,
      });
    }

    borrowerDetails?.kycDocs?.forEach((doc) => {
      if (doc.status == 0) {
        images.push({
          id: doc.id,
          status: doc.status,
        });
      }
    });

    beneficiaryDetails?.kycDocs?.forEach((doc) => {
      if (doc.status == 0) {
        images.push({
          id: doc.id,
          status: doc.status,
        });
      }
    });
    try {
      let postBody = {};
      if (type == "TRADER") {
        postBody = {
          adminId: adminId,
          appId: appId,
          comments: borrowerDetails?.comments,
          cpId: cpId || 0,
          eligibleLimit: borrowerDetails?.eligibleLimit,
          farmerId: 0,
          intrestRate: borrowerDetails?.intrestRate,
          kycDocs: images,
          kycStatus: borrowerDetails?.status,
          onoScore: borrowerDetails?.onoScore,
          pfFee: borrowerDetails?.platformFee,
          renewDate: borrowerDetails?.renewDate || null,
          rmId: borrowerDetails?.rmId,
          tenure: borrowerDetails?.tenureString || "",
          type: type,
          accNum: borrowerDetails?.accNum,
          bankName: borrowerDetails?.bankName,
          ifscCode: borrowerDetails?.ifscCode,
          accHolder: borrowerDetails?.accHolder,
        };
      }
      if (type == "FARMER") {
        postBody = {
          adminId: adminId,
          appId: appId,
          comments: beneficiaryDetails?.comments,
          cpId: cpId || 0,
          eligibleLimit: 0,
          farmerId: beneficiaryDetails?.farmerId,
          intrestRate: beneficiaryDetails?.intrestRate,
          kycDocs: images,
          onoScore: beneficiaryDetails?.onoScore,
          kycStatus: beneficiaryDetails?.appStatus,
          pfFee: 0,
          renewDate: beneficiaryDetails?.renewDate || null,
          rmId: beneficiaryDetails?.rmId,
          type: type,
          accHolder: beneficiaryDetails?.accHolder || "",
          accNum: beneficiaryDetails?.accNum || "",
          bankName: beneficiaryDetails?.bankName || "",
          ifscCode: beneficiaryDetails?.ifscCode || "",
        };
      }

      await handlePutCall<any>({
        URL: "admin/cash/borrowers",
        apiParams: postBody,
      }).then(({ data, status }) => {
        toast.success(status.description);
        if (type == "TRADER") {
          navigate(`/borrower-detail/${cpId}/${borrowerDetails.platform}`);
        } else {
          navigate(
            `/beneficiary-detail/${cpId}/${appId}/${borrowerDetails.platform}`
          );
        }
        modalClose(false);
        // closeModal(false);
      });
    } catch (ex) {}
  };
  return {
    borrowerDetails,
    handleDateSelection,
    onChangeStatus,
    setBorrowerDetails,
    submit,
    onRemoveReceiptImage,
    onUploadReceiptImage,
    kycDocs,
    beneficiaryDetails,
    setBeneficiaryDetails,
    onUploadBeneficiaryImage,
    onRemoveBeneficiaryImage
  };
};
export default useBorrowerDetailsEdit;

import { useEffect, useState } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient"; 
import {
  TPaymentHistory,
  TTradeDocument,
} from "../types/index";
import { TRootState } from "../store";

const usePayNotifications = ({status}:{status?: boolean}) => {
  const { handleGetCall } = useAxiosClient();
  const { adminId, tententType, refresh } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      tententType: state.auth.userInfo?.type,
      refresh: state.trades.refresh,
    })
  );
  const [notifications, setNotifications] = useState<TPaymentHistory[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    handleGetCall<TPaymentHistory[]>(
      `/admin/trade-platform/trades/adminId/${adminId}?tenant=${tententType}`
    ).then(({ data }) => {
      if (data) {
        let d = data.map((d) => {
          let images: TTradeDocument[] = [];
          d.imgs.forEach((img) => {
            images.push({
              ...img,
              docType: img.url.includes(".pdf") ? "pdf" : "image",
            });
          });
          return {
            ...d,
            id: d.pmtId,
            pmtType: d.paymentType,
            images: images,
          };
        });
        setNotifications(d);
        setIsLoading(false);
      } else {
        setNotifications([]);
        setIsLoading(false);
      }
    });
  }, [status, refresh]);

  return { notifications, refresh, isLoading, setIsLoading };
};
export default usePayNotifications;
